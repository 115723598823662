.nav_riot .sidebarOverlay {
    opacity: 0;
}

.nav_riot .sidebarOverlay.show {
    background-color: #0e171e8a;
    bottom: 0;
    height: 100%;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    transition: all .3s ease-out;
    width: 100%;
    z-index: 9999;
}

.SidebarInner {
    z-index: 99999;
    position: fixed;
    transition: all .3s ease-out;
    top: 0;
    padding: 0px 37px;
    background-color: var(--secondary-color);
    bottom: 0;
    left: -100%;
    width: 40%;
}

.sidebar.show {
    opacity: 1;
    left: 0;
}

.SidebarInner.show {
    left: 0;
    transition: all .3s ease-out;
}

.sideMenu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.langSwitch button span.iconup svg:last-child {
    display: none;
}

.langSwitch button span.icondown svg:first-child {
    display: none;
}

.langSwitch button span {
    display: flex;
    align-items: center;
}

.nav_brand {
    display: flex;
    gap: 35px;
    align-items: center;
    justify-content: center;
}

.nav_brand img {
    max-width: 100%;
}

nav .nav_Links .sideLinks {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    list-style: none;
}

nav .nav_Menu {
    display: flex;
    gap: 50px;
    flex-direction: column;
}

nav .nav_Links .sideLinks li a {
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--text-white);
    font-size: 12px;
    font-weight: 300;
}

.navsocial ul.socailLinks {
    display: flex;
    gap: 22px;
    list-style: none;
    justify-content: center;
}

.navsocial {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.navsocial ul.socailLinks li a {
    color: var(--primary-color);
    font-size: 20px;
}

.sidebarBtn button {
    background: none;
    border: none;
}

.sidebarBtn button img {
    width: 20px;
}

nav.nav_riot {
    width: 100%;
    z-index: 9999;
    position: fixed;
}

nav .navInner {
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;
}

.SideHeader.sidebarBtn {
    padding: 20px 0px;
}

.val_logo img {
    width: 50px;
}

.riot_logo img {
    width: 100px;
}

.globeImg img {
    width: 50%;
}

.globeImg {
    display: flex;
    justify-content: flex-end;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
}

.dropdown-toggle img {
    margin-right: 0.5rem;
}

.dropdown-menu {
    min-width: 0;
}

.dropdown-item img {
    margin-right: 0.5rem;
}

.langSwitch {
    display: flex;
    position: relative;
    gap: 10px;
    align-items: center;
}

.langSwitch button {
    background-color: transparent;
    border: none;
    color: var(--text-white);
    display: flex;
    align-items: center;
    gap: 10px;
}

.drop_opt {
    right: 23px;
    position: absolute;
    top: 30px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.drop_opt span {
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;
    color: var(--bg-color);
}

[dir='rtl'] .langSwitch button,
.langSwitch .drop_opt span {
    font-family: 'CairoRegular';
    font-size: 16px;
}

[dir='ltr'] .langSwitch button,
.langSwitch .drop_opt span {
    font-family: 'DINRegular';
    font-size: 16px;

}

[dir='ltr'] .drop_opt {
    right: 14px;
}

[dir='rtl'] .drop_opt {
    left: 32px;
}

.drop_opt span {
    font-size: 12px;
    border: 1px solid var(--text-white) !important;
    display: block;
    width: min-content;
    padding: 4px;
}

@media (min-width:320px) {
    nav .navInner {
        padding: 20px 25px;
    }

    .HamburgerBtn.sidebarBtn,
    .SidebarInner,
    .sidebarOverlay {
        display: initial;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {

    .HamburgerBtn.sidebarBtn,
    .SidebarInner,
    .sidebarOverlay {
        display: none;
    }

    nav .navInner {
        padding: 25px 50px;
    }

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */
}

/* Shamrin start here */
.langSwitch button,
.langSwitch .drop_opt span {
    text-transform: uppercase;
    font-weight: normal !important;
}

.langSwitch svg {
    width: 10px;
    margin: -4px 0 0 4px;
}

.langSwitch button {
    gap: 0px;
}

.globeImg img {
    margin-bottom: 3px;
    width: 80%;
}

.nav_riot .sidebarOverlay.show {
    background-color: rgb(0 0 0 / 78%);
}

@media (max-width: 1600px) {

    .langSwitch button,
    .langSwitch .drop_opt span {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .globeImg img {
        margin-bottom: 4px;
        width: 55%;
    }

    .langSwitch button,
    .langSwitch .drop_opt span {
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .sidebarBtn button img {
        width: 30px;
    }

    nav .nav_Links .sideLinks li a {
        font-family: DINRegular;
    }

    .SidebarInner {
        padding: 0px 20px;
        width: 70%;
    }

    .navsocial ul.socailLinks {
        gap: 33px;
    }

    .navsocial ul.socailLinks li a {
        font-size: 53px;
    }

    nav .nav_Links .sideLinks li a {
        font-size: 16px;
    }

    .riot_logo img {
        width: 130px;
    }

    .val_logo img {
        width: 80px;
    }

    .nav_brand {
        gap: 40px;
    }

    .langSwitch button,
    .langSwitch .drop_opt span {
        font-size: 12px;
    }

    .globeImg img {
        margin-bottom: 1px;
        width: 55%;
    }
}

@media (max-width: 480px) {
    .riot_logo img {
        width: 90px;
    }

    .val_logo img {
        width: 55px;
    }

    .nav_brand {
        gap: 20px;
    }

    .navsocial ul.socailLinks li a {
        font-size: 36px;
    }

    nav .nav_Links .sideLinks li a {
        font-size: 14px;
    }

    nav .nav_Links .sideLinks {
        gap: 16px;
    }
}

@media (max-width: 390px) {
    .sidebarBtn button img {
        width: 30px;
    }

    /* .val_logo img {
        width: 100px;
    } */
    .riot_logo img {
        width: 90px;
    }

    .val_logo img {
        width: 55px;
    }

    .nav_brand {
        gap: 20px;
    }

    nav .nav_Links .sideLinks li a {
        font-family: DINRegular;
    }

    .navsocial ul.socailLinks li a {
        font-size: 36px;
    }

    .SidebarInner {
        width: 65%;
    }

    nav .nav_Links .sideLinks li a {
        font-size: 14px;
    }

    nav .nav_Links .sideLinks {
        gap: 16px;
    }
}

@media (max-width: 320px) {
    .SidebarInner {
        width: 60%;
    }
}

.drop_opt span {
    padding: 4px 13px 1px;
}

[dir='ltr'] .drop_opt {
    right: -4px;
}

.drop_opt {
    top: 35px;
}

[dir='rtl'] .langSwitch svg {
    margin: 8px 10px 0 0px;
}

[dir='rtl'] .drop_opt {
    top: 40px;
    right: 5px;
    left: 0;
}

@media (max-width: 1024px) {
    [dir='ltr'] .drop_opt {
        right: 0px;
        top: 28px;
    }

    [dir='rtl'] .drop_opt {
        right: 15px;
    }
}

@media (max-width: 680px) {
    [dir='ltr'] .drop_opt {
        top: 25px;
    }

    [dir='rtl'] .drop_opt {
        top: 35px;
    }
}

@media (max-width: 640px) {
    .drop_opt span {
        padding: 4px 13px 2px;
    }

    nav .nav_Menu {
        margin-top: -130px;
    }
}

/* 04.03.2023 Sh*/

@media (min-width: 1919px) {
    nav .navInner {
        padding: 25px 80px;
    }
}

/* Shamrin ends here */
.socialShare_page{
    margin: 0 auto;
    background: transparent;
    padding: 2% 0;
    max-width: 50vw;
    
}

.right_result {
    position: relative;
}
.shareCanvas .Profile > img {
    width: 100%;
    display: block;
}

.canvas2.shareCanvas {
    position: relative;
    padding: 10% 0px;
    width: calc(100% - 80px);
    margin: 10px auto;
    padding: 0;
}
.shareCanvas .Profile {
    position: relative;
    border: 1px solid #e3dbd3;
}
.shareCanvas .Profile:before {
    content: "";
    position: absolute;
    top: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-bottom: 0px;
}

[dir="ltr"].Profile.shareProfileMM span.textProfile {
    text-align: left;
    font-family: DINRegular;
}

[dir="rtl"].Profile.shareProfileMM span.textProfile {
    text-align: right;
    font-family: 'CairoRegular';
}

[dir="ltr"].Profile.shareProfileMM 
 span.numProfile {
    text-align: right;
}

[dir="rtl"].Profile.shareProfileMM span.numProfile {
    text-align: left;
}
.shareCanvas .Profile:after {
    content: "";
    position: absolute;
    bottom: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-top: 0px;
}
.shareCanvas .profileData {
    background: rgb(0 0 0 / 52%);
    padding: 10px;
    width: 84.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
}
.shareCanvas .profileData .profileDetails {
    width: 32%;
    display: flex;
    justify-content: space-between;
}
.shareCanvas span.textProfile {
    width: 50%;
    font-size: 10.4px !important;
    text-transform: uppercase;
}
.shareCanvas span.numProfile {
    font-size: 25.28px !important;
    line-height: 1;
    width: 45%;
    font-family: DINBold;
    text-align: right;
}
.shareCanvas .profileQuizTitle {
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 79.2%;
    text-align: center;
    z-index: 9;
}
.shareCanvas .profileQuizTitle h2.profileHead {
    z-index: 9;
    position: relative;
    font-weight: 800;
    font-size: 26.4px;
    display: inline-block;
    padding-top: 7px;
    line-height: 1;
    font-family: TungstenBold;
    color: #101924;
}
.shareCanvas .profileQuizTitle span.bgColor {
    position: absolute;
    left: 50%;
    height: 0px;
    width: 85%;
    border-bottom: 39px solid #eaeeb2;
    border-left: 39px solid transparent;
    border-right: 39px solid transparent;
    transform: translateX(-50%);
    top: 0;
}
.socialShare_page {
    padding: 78px 0;
}
.socialShare_page {
    max-width: 630px;
    height: 100vh;
}
@media (min-width:1920px) { 
}

@media (min-width:2561px) { 
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }  
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }  
    .shareCanvas .profileData {
        width: 87%;
    }
    .shareCanvas span.numProfile {
        font-size: 102px !important;
        margin-top: 13px;
    }
    .shareCanvas span.textProfile {
        font-size: 20.8px !important;
    }
}
@media (max-width:2560px) { 
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }    
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }  
    .shareCanvas .profileData {
        width: 87%;
    }
    .shareCanvas span.numProfile {
        font-size: 102px !important;
        margin-top: 13px;
    }
    .shareCanvas span.textProfile {
        font-size: 20.8px !important;
    }
}
@media (max-width:1920px) { 
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 88%;
    }
    .shareCanvas .profileData {
        width: 86%;
    }
    .shareCanvas span.textProfile {
        font-size: 15px !important;
    }
    .shareCanvas span.numProfile {
        font-size: 72px !important;
        margin-top: 13px;
    }
  
}
@media (max-width: 1680px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 26.4px;
        padding-top: 7px;
    }
    .shareCanvas .profileData {
        width: 85.5%;
    }
    .shareCanvas span.numProfile {
        font-size: 58px !important;
        margin-top: 7px;
    }
    .shareCanvas span.textProfile {
        font-size: 12px !important;
    }
    
    .socialShare_page {
        max-width: 550px;
    }
}
@media (max-width: 1600px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }
    .socialShare_page {
        max-width: 45vw;
    }
    
}
@media (max-width: 1536px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 87%;
    }
    
    .socialShare_page {
        max-width: 511px;
    }
}
@media (max-width: 1440px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 86%;
    }
    .shareCanvas .profileQuizTitle {
        top: 12.5%;
        width: 78.8%;
    }
    .shareCanvas .profileData {
        width: 85%;
    }
    .shareCanvas span.numProfile {
        font-size: 42px !important;
    }
    .shareCanvas span.textProfile {
        font-size: 10.4px !important;
    }
    .socialShare_page {
        min-height: calc(100vh - 50px);
    }
    .socialShare_page {
        max-width: 485px;
    }
}
@media (max-width: 1366px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 85%;
    }
    .socialShare_page {
        max-width: 42vw;
    }
    .shareCanvas .profileQuizTitle {
        width: 78%;
    }
    .socialShare_page {
        max-width: 450px;
    }
    .socialShare_page {
        height: unset;
    }
}

@media (max-width: 1280px) {
    .canvas2.shareCanvas {
        padding: 0% 10px;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 20.8px;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        border-bottom: 32px solid #eaeeb2;
        border-left: 32px solid transparent;
        border-right: 32px solid transparent;
        width: 85.5%;
    }
    .shareCanvas .profileData {
        width: 84.5%;
    }
    .shareCanvas span.numProfile {
        font-size: 35px !important;
    }
    .shareCanvas span.textProfile {
        font-size: 8.8px !important;
    }
    .socialShare_page {
        max-width: 400px;
    }
}
@media (max-width: 1160px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 83.5%;
    }
    .shareCanvas .profileData {
        width: 84%;
    }
    .shareCanvas span.numProfile {
        font-size: 30px !important;
        width: 40%;
    }
    .shareCanvas span.textProfile {
        font-size: 8.48px !important;
        width: 50%;
    }
}
@media (max-width: 1024px){
    .canvas2.shareCanvas {
        margin: 10px 0;
        width: 100%;
        padding: 0;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 85.5%;
        border-bottom: 28px solid #eaeeb2;
        border-left: 28px solid transparent;
        border-right: 28px solid transparent;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 16px;
        padding-top: 7px;
    }
    .shareCanvas .profileQuizTitle {
        width: 79.8%;
    }
    .shareCanvas .profileQuizTitle {
        width: 79%;
    }
 
}
@media (max-width: 814px) {
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 83.5%;
    }
    .shareCanvas .profileData {
        width: 82.5%;
    }
    .shareCanvas span.numProfile {
        font-size: 22px !important;
    }
    .shareCanvas span.textProfile {
        font-size: 6.4px !important;
    }
    .shareCanvas .profileQuizTitle {
        width: 78.5%;
    }
}
@media (max-width: 768px) {
    .canvas2.shareCanvas {
        margin: 10px 0px;
    }
    .shareCanvas .profileQuizTitle {
        top: 13%;
        width: 79%;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 89.5%;
    }
    .shareCanvas .profileData {
        width: 86%;
    }
    .shareCanvas span.textProfile {
        font-size: 13.12px !important;
    }
    .shareCanvas span.numProfile {
        font-size: 22.4px !important;
    }
    
    .shareCanvas span.textProfile {
        font-size: 16px !important;
        width: 60%;
    }
    .shareCanvas .profileData .profileDetails {
        width: 33%;
        gap: 0;
    }
    .shareCanvas span.numProfile {
        font-size: 40px !important;
        width: 35%;
    }
    .socialShare_page {
        padding: 80px 0;
        max-width: 90%;
    }
}
@media (max-width: 767px){
    .shareCanvas .profileQuizTitle {
        top: 12.5%;
        width: 77.5%;
    }
    .shareCanvas .profileData {
        width: 85.5%;
    }
}
@media (max-width: 680px){
    .shareCanvas .profileQuizTitle {
        width: 77%;
    }
    .shareCanvas .profileData {
        width: 85.2%;
    }
    .shareCanvas span.numProfile {
        font-size: 35px !important;
    }
    .shareCanvas span.textProfile {
        font-size: 13px !important;
    }
}
@media (max-width: 580px){
    .shareCanvas .profileQuizTitle {
        width: 76.5%;
    }
    .shareCanvas .profileData {
        width: 84.2%;
    }
    .shareCanvas span.textProfile {
        font-size: 11px !important;
    }
    .shareCanvas span.numProfile {
        font-size: 27px !important;
        margin-top: 2px;
    }   
    
}
@media (max-width: 500px){
    .shareCanvas .profileQuizTitle {
        width: 76.5%;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        font-size: 13.6px;
        padding-top: 7px;
    }
    .shareCanvas span.textProfile {
        font-size: 10px!important;
    }
    .shareCanvas span.numProfile {
        font-size: 20px !important;
    } 
}
@media (max-width: 480px){
    .shareCanvas .profileQuizTitle {
        width: 76%;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        border-bottom: 22px solid #eaeeb2;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        width: 89.5%;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        padding-top: 6px;
    }
    .shareCanvas .profileQuizTitle {
        width: 76.2%;
        top: 13%;
    }
}
@media (max-width: 440px) {
    
    .shareCanvas .profileData {
        width: 83.2%;
    }
    
    .shareCanvas span.textProfile {
        font-size: 8px!important;
    }
    .shareCanvas span.numProfile {
        font-size: 18px !important;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 88.5%;
    }
}

@media (max-width: 390px){
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }
    .shareCanvas .profileQuizTitle h2.profileHead {
        padding-top: 5px;
    }
    .shareCanvas .profileData {
        width: 88%;
        gap: 0;
        padding-left: 2px;
        padding-right: 2px;
    }
    .shareCanvas span.numProfile {
        font-size: 17px !important;
    }
    .shareCanvas span.textProfile {
        font-size: 7px!important;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 89%;
    }
}
@media (max-width: 360px){
    .shareCanvas .profileQuizTitle {
        width: 76%;
    }
}
@media (max-width: 340px){
    .shareCanvas .profileQuizTitle {
        width: 75.5%;
    }
    .shareCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }
}
@media (max-width: 320px){
    .shareCanvas .profileQuizTitle {
        width: 74.5%;
    }
    .shareCanvas .profileData {
        width: 87%;
    }
}



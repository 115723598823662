.QuizMain {
    min-height: calc(100vh - 50px);
    display: flex;
    align-items: stretch;
}

.question {
    display: flex;
    width: 100%;
    align-items: center;
}

.leftQuestion img {
    height: 100%;
}

.question .leftQuestion,
.question .rightQuestion {
    flex: 1 1;
}

.leftQuestion {
    display: flex;
    justify-content: center;
}

.questionSlider .swiper {
    width: 100%;
}

.rightQuestion {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

.rightQuestion .nextButton button {
    width: 80%;
    margin: auto;
}


.Hour,.minutes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
}
.nextButton {
    display: flex;
    justify-content: center;
}

.QuizBtn.btn_Parent.btn_red {
    width: 80%;
}

.QuizBtn.btn_Parent.btn_red button {
    width: 100%;
}

span.swiper-pagination-bullet.answered {
    opacity: 1;
    background-color: var(--primary-color);
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--primary-color);
}

.custom-pagination span {
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: transparent;
    display: block;
    border: 1px solid #bbbbbb;
}

.questionSlider .custom-pagination {
    display: flex;
    gap: 50px;
    z-index: 999;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: -40px;
    right: 50%;
    transform: translateX(50%);
}


.QuestinInner {
    position: relative;
}

.custom-pagination span.answered,
.custom-pagination span.active {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.questionType {
    width: 81%;
    margin: auto;
    margin-bottom: 10px;
}

.Time .form-group {
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
}

.questionType .Time {
    background-color: var(--field-bg);
    display: flex;
    padding: 15px;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin: auto;
}

.questionType .Time .form-group input {
    font-weight: 800;
    letter-spacing: 3px;
    outline: none;
    height: 55px;
    background-color: transparent;
    border: none;
    color: var(--text-white);
    font-size: 80px;
    width: 65px;
}

.questionType .Time label {
    font-size: 18px;
    font-weight: 900;
    color: var(--text-white);
}

.Time .form-group label {
    font-size: 12px;
}

.QueOption .opt input {
    opacity: 0;
    display: none;
}

.QueOption .form-group {
    display: flex;
    gap: 14px;
    justify-content: space-around;
}

.QueOption .form-group .opt {
    width: 100%;
}

.QueOption .form-group .opt .btn_Parent label {
    color: var(--text-white);
    cursor: pointer;
    position: relative;
    z-index: 9;
    display: flex;
    font-weight: 800;
    border: 1px solid var(--text-white);
    font-size: 32px;
    padding: 25px 40px;
    justify-content: space-around;
}

.QueOption .form-group .opt input[type="radio"]:checked+.btn_Parent label {
    background-color: var(--field-bg);
}

.custom-pagination span::before {
    content: '';
    width: 42px;
    left: 14px;
    display: block;
    position: absolute;
    border: 0.3px solid #fff;
    top: 4px;
}

.custom-pagination span {
    position: relative;
}

.custom-pagination span:last-child::before {
    position: unset;
    content: '';
    border: none;
    display: initial;
}

.custom-pagination span.answered::before,
.custom-pagination span.active::before {
    content: '';
    border-color: var(--primary-color);
}

[dir='ltr'] .Prompt p {
    font-family: 'DINRegular';
}

[dir='ltr'] .QuizBtn.btn_Parent.btn_red button {
    font-family: 'TungstenBold';
}

.questionType .Time .form-group input {
    font-family: 'TungstenBold';
}

[dir='ltr'] .questionType .Time label {
    font-family: 'DINRegular';
}

[dir='rtl'] .Prompt p {
    font-family: 'CairoRegular';
}


[dir='rtl'] .questionType .Time {
    font-family: 'GEBold';
}

[dir='rtl'] .QuizBtn.btn_Parent.btn_red button {
    font-family: 'GEBold';
}

[dir='ltr'] .QuizBtn.btn_Parent.btn_red button {
    font-family: 'TungstenBold';
}

[dir='ltr'] .QueOption .form-group .opt .btn_Parent label {
    font-family: 'TungstenBold';
}

[dir='rtl'] .QueOption .form-group .opt .btn_Parent label {
    font-family: 'GEBold';
}

.QuizBtn.btn_Parent.btn_red button {
    font-size: 24px;
}

.QueOption .form-group .opt .btn_Parent label {
    display: flex;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
}

.opt>.btn_Parent {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.QueOption .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: stretch;
}

[dir='ltr'] .err {
    font-family: 'TungstenBold';
    font-size: 20px;
}

[dir='rtl'] .err {
    font-size: 20px;
    font-family: 'CairoRegular';
}

.questionSh .questionType .Time .form-group input::placeholder {
    color: var(--text-white);
}

.SwiperParent {
    display: none;
}

.SwiperParent.swiperShow {
    display: initial;
}

@media (min-width:320px) {

    .Prompt p,
    .questionType .Time label {
        font-size: 14px;
    }

    .Prompt {
        width: 76%;
        margin-top: 40px !important;
    }

    .Prompt::after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        /* border-left: 9px solid #061d2a;
        border-right: 10px solid transparent;
        border-top: 9px solid #fff0;
        border-bottom: 9px solid #03222f; */
        border-left: 11px solid #061d2a;
        border-right: 11px solid transparent;
        border-top: 11px solid #fff0;
        border-bottom: 11px solid #03222f;
        left: 0px;
        top: -17px;
        z-index: 9;
    }

    .Prompt::before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid var(--text-white);
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid var(--text-white);
        left: -1px;
        top: -20px;
    }

    /* .QuestinInner{
        padding: 0px 25px;
    } */
    .questionSlider .custom-pagination {
        top: 10px;
    }

    .QueOption .form-group .opt .btn_Parent label {
        font-size: 24px;
        padding: 25px 20px;
    }

    .question {
        padding: 60px 0px;
    }

    .question .leftQuestion {
        width: 100%;
    }

    .question .rightQuestion {
        width: 100%;
    }

    .question {
        flex-direction: column;
    }

    .leftQuestion img {
        width: 100%;
    }

    .leftQuestion a:first-child {
        display: none;
    }

    .leftQuestion a:last-child {
        display: initial;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
    .QueOption .form-group .opt .btn_Parent label {
        font-size: 24px;
        padding: 25px 20px;
    }

    .leftQuestion {
        padding: 0px;
    }

    .Prompt {
        width: 86%;
    }

    /* .QuestinInner{
        padding: 0px 75px;
    } */
    .question {
        padding: 60px 0px;
    }

    .question .leftQuestion {
        width: 100%;
    }

    .question .rightQuestion {
        width: 100%;
    }

    .leftQuestion img {
        width: 100%;
    }

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    .Prompt::before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid var(--text-white);
        border-top: 10px solid transparent;
        border-bottom: 10px solid var(--text-white);
        left: -20px;
        top: auto;
        bottom: -1px;
    }

    .Prompt::after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid #002a3a;
        border-top: 10px solid #fff0;
        border-bottom: 10px solid #002939;
        left: -18px;
        top: auto;
        bottom: 0px;
    }

    .questionSlider .custom-pagination {
        top: -40px;
    }

    .QueOption .form-group .opt .btn_Parent label {
        font-size: 24px;
        padding: 25px 20px;
    }

    .leftQuestion {
        padding: 0px 0px 0px 50px;
    }

    .Prompt {
        width: 86%;
        margin-top: 0px;
    }

    .QuestinInner {
        padding: 0px 2% 0px 0px;
    }

    .question {
        padding: 0px;
    }

    .question .leftQuestion {
        width: 50%;
    }

    .question .rightQuestion {
        width: 50%;
    }


    .question {
        flex-direction: row;
    }

    .leftQuestion img {
        width: 100%;
    }

    .leftQuestion a:first-child {
        display: initial;
    }

    .leftQuestion a:last-child {
        display: none;
    }

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    .QueOption .form-group .opt .btn_Parent label {
        font-size: 32px;
        padding: 25px 20px;
    }

    .Prompt {
        width: 93%;
    }

    .QuestinInner {
        padding: 0px 50px 0px 0px;
    }

    .question .leftQuestion {
        width: 50%;
    }

    .question .rightQuestion {
        width: 50%;
    }

    .leftQuestion img {
        width: 100%;
    }

    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    .QueOption .form-group .opt .btn_Parent label {
        font-size: 32px;
        padding: 25px 20px;
    }

    .QuestinInner {
        padding: 0px 50px 0px 0px;
    }

    .question .leftQuestion {
        width: 50%;
    }

    .question .rightQuestion {
        width: 50%;
    }

    .leftQuestion img {
        width: 75%;
    }

    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    .QueOption .form-group .opt .btn_Parent label {
        font-size: 32px;
        padding: 25px 20px;
    }

    .QuestinInner {
        padding: 0px 50px 0px 0px;
    }

    .leftQuestion img {
        width: 89%;
    }

    /* hi-res laptops and desktops */
}





/* Shamrin Css Start */


.questionSh .questionType .Time .form-group input {
    font-size: 125px;
    width: 125px;
    text-align: center;
}

.questionSh .colon_dots span {
    width: 16px;
    height: 16px;
}

.questionSh .questionType .Time label {
    font-weight: normal;
    font-size: 24px !important;
    margin-top: -15px;
    text-transform: uppercase;

}

.questionSh .QuizBtn.btn_Parent.btn_red button {
    font-size: 32px;
}

.questionSh .btn_Parent:before,
.questionSh .btn_Parent:after {
    z-index: -1;
}

.questionSh .custom-pagination span {
    width: 16px;
    height: 16px;
}

.mainDiv.ltr .questionSh .custom-pagination span::before {
    left: 20px;
    top: 7px;
}

.mainDiv.rtl .questionSh .custom-pagination span::before {
    right: 20px;
    top: 7px;
}

.questionSh .prompt_Parent {
    margin-bottom: 50px;
}

.questionSh .btn_Parent:before {
    height: 40%;
}

.questionSh .QueOption .form-group .opt .btn_Parent label {
    font-size: 32px;
}

[dir='ltr'] .err {
    font-family: 'TungstenBold';
    text-transform: unset;
}

.questionSh .QueOption .form-group .opt {
    width: 100%;
}

.questionSh .QueOption .form-group .opt:last-child {
    margin-left: auto;
}

.questionSh .QueOption .form-group .opt .btn_Parent label {
    text-transform: uppercase;
}

@media (min-width: 1281px) {
    .questionSh .QuestinInner {
        padding-right: 0;
    }

    .question .rightQuestion {
        padding-right: 5%;
        width: 45%;
        margin: 0 auto;
    }
}

@media (min-width: 1441px) {
    .questionSh .questionType .Time .form-group input{
        height: 115px;
    }
}
@media (min-width: 1495px) {
    .questionSh .questionType .Time .form-group input{
        height: 115px;
    }
}
@media (min-width: 1441px) {
    .questionSh .leftQuestion img {
        width: 80%;
    }
    /* .questionSh .questionType .Time .form-group input{
        height: 120px;
    } */
}
@media (min-width: 1463px) {
    .questionSh .questionType .Time .form-group input{
        height: 80px;
    }
}

@media (min-width: 1919px) {
    .questionSh .questionType .Time .form-group input{
        height: 140px;
    }
}
/* @media (min-width: 1366px) {

    .questionSh .questionType .Time .form-group input{
        height: 100px;
    }
} */
@media (min-width: 1367px) {
    .questionSh .leftQuestion img {
        width: 75%;
    }
}

@media (min-width: 1281px) {
    .questionSh .leftQuestion img {
        width: 70%;
    }
}

@media (max-width: 1919px) {
    .questionSh .QuizBtn.btn_Parent.btn_red button {
        font-size: 22px;
    }

    .questionSh .questionType .Time label {
        font-size: 18px !important;
    }

    .questionSh .questionType .Time .form-group input {
        font-size: 100px;
        width: 100px;
    }

    .questionSh .colon_dots span {
        width: 12px;
        height: 12px;
    }

    .Prompt.promptSh p {
        font-size: 22px;
    }

    .questionSh .custom-pagination span {
        width: 12px;
        height: 12px;
    }

    .mainDiv.ltr .questionSh .custom-pagination span::before {
        left: 16px;
        top: 5px;
    }

    .mainDiv.rtl .questionSh .custom-pagination span::before {
        right: 16px;
        top: 5px;
    }
}

@media (max-width: 1536px) {
    .questionSh .QuizBtn.btn_Parent.btn_red button {
        font-size: 18px;
    }

    .questionSh .questionType .Time label {
        font-size: 15px !important;
        margin-top: 0px;
    }

    .questionSh .colon_dots span {
        width: 8px;
        height: 8px;
    }

    .questionSh .questionType .Time .form-group input {
        font-size: 80px;
        width: 80px;
    }

    .questionSh .QueOption .form-group .opt .btn_Parent label {
        font-size: 22px;
    }
}

@media (max-width: 1440px) {
    .questionSh .questionType .Time .form-group input {
        font-size: 60px;
        width: 60px;
    }

    .questionSh .questionType .Time label {
        font-size: 13px !important;
    }

    [dir='ltr'] .err,
    [dir='rtl'] .err {
        font-size: 16px;
    }
}

@media (max-width: 1366px) {
    .questionSh .QuizBtn.btn_Parent.btn_red button {
        font-size: 16px;
    }

    .questionSh .custom-pagination span {
        width: 10px;
        height: 10px;
    }

    .mainDiv.ltr .questionSh .custom-pagination span::before {
        left: 14px;
        top: 4px;
    }

    .mainDiv.rtl .questionSh .custom-pagination span::before {
        right: 14px;
        top: 4px;
    }

    [dir='ltr'] .err {
        font-size: 14px;
    }

    [dir='rtl'] .err {
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .Prompt.promptSh p {
        font-size: 16px;
    }

    .Prompt.promptSh {
        width: 85%;
    }

    .questionSh .prompt_Parent {
        margin-bottom: 30px;
    }
}

@media (max-width: 1024px) {
    .Prompt.promptSh p {
        padding: 0px 20px 15px 20px;
    }

    .Prompt.promptSh {
        margin-top: 0px !important;
    }
}

@media (max-width: 768px) {
    .Prompt.promptSh {
        margin-bottom: 20px;
    }
}

@media (max-width: 640px) {
    .questionSh .QuizBtn.btn_Parent.btn_red button {
        padding: 6px 10px;
        font-size: 22px;
    }

    .question .rightQuestion {
        margin-top: 30px;
    }

    .questionSh .prompt_Parent {
        margin-bottom: 45px;
        padding-top: 30px;
        margin-top: 20px;
    }

    .question.questionSh {
        padding: 80px 0 60px;
    }

    .questionSh .questionType {
        width: 90%;
    }

    .questionSh .QuizBtn.btn_Parent.btn_red {
        width: 88.5%;
    }

    .questionSh .err {
        max-width: 90%;
        margin: 15px auto 0;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .questionSh .QuizBtn.btn_Parent.btn_red button {
        font-size: 18px;
    }
}

/* Shamrin Css Ends */
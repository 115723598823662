.btn_Parent button::after {
    width: 6px;
    content: '';
    height: 6px;
    background-color: var(--dark-bg);
    display: block;
    transition: transform .3s ease-out;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.btn_Parent button {
    margin: 0;
    position: relative;
    width: 100%;
    border: none !important;
    padding: 12px 10px;
    overflow: hidden;
    z-index: 999;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--text-white);
}
.btn_Parent.btn_transparent button {
    background: transparent;
    border: 1px solid var(--text-white) !important;
}

.btn_Parent.btn_red button {
    background-color: var(--primary-color);
}
.btn_Parent button{
    transition:transform .3s ease-out,-webkit-transform .3s ease-out;
}
.btn_Parent button::before {
    content: '';
    transform: translateX(-100%) skew(-10deg);
    width: 120%;
    z-index: -9;
    display: block;
    left: -8%;
    transition: transform .3s ease-out;
    top: 0px;
    height: 100%;
    position: absolute;
}
.btn_Parent.btn_red button::before {
    background-color: var(--dark-btn);
}
.btn_Parent.btn_transparent button::before {
    background-color: var(--white-color);
}
.btn_Parent:hover>button::before {
    left: 0%;
    transform: translateX(-10%) skew(-10deg);
}
.btn_Parent:hover>button::after {
    background-color: white;
}
.btn_Parent.btn_red:hover>button{
    color: var(--text-white);
}
.btn_Parent.btn_transparent:hover>button{
    color: var(--dark-btn);
}
.btn_Parent:after {
    content: '';
    display: block;
    width: 100%;
    bottom: 0px;
    right: 0px;
    height: 40%;
    border:1px solid var(--text-white);
    position: absolute;
    border-top: none;
}
.btn_Parent:before {
    top: 0px;
    content: '';
    display: block;
    width: 100%;
    right: 0px;
    height: 36%;
    border:1px solid var(--text-white);
    position: absolute;
    border-bottom: none;
}
.btn_Parent {
    position: relative;
    cursor: pointer;
    padding: 5px 4px 5px 3px;
}


/* Shamrin Css button square dot hide  */
.btn_Parent button::after {
    display: none !important;
}
/* .btn_Parent button::before {
    display: none !important;
} */

.btn_Parent.btn_red button{
    border: 1px solid transparent !important;
}
.btn_Parent.btn_red button:hover {
    border: 1px solid #fff !important;
}
/* Shamrin Css  button square dot hide  End */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #FF294F;
  --secondary-color: #101924;
  --green-color: #0BC028;
  --bg-color: #F9F5EB;
  --text-white: #EDE8E0;
  --white-color: #FFFFFF;
  --dark-btn: #1E3943;
  --dark-bg: #0E171E;
  --field-bg: #8d978f;
}

/* .mainDiv.rtl{
    font-family: 'Noto Sans Arabic', sans-serif;
}
.mainDiv.ltr{
font-family: 'Raleway', sans-serif;
} */
/* Arabic Fonts */
@font-face {
  font-family: CairoBlack;
  src: url('./assets/Fonts/Cairo/Cairo-Black.ttf');
}

@font-face {
  font-family: CairoBold;
  src: url('./assets/Fonts/Cairo/Cairo-Bold.ttf');
}

@font-face {
  font-family: CairoExtraBold;
  src: url('./assets/Fonts/Cairo/Cairo-ExtraBold.ttf');
}

@font-face {
  font-family: CairoExtraLight;
  src: url('./assets/Fonts/Cairo/Cairo-ExtraLight.ttf');
}

@font-face {
  font-family: CairoLight;
  src: url('./assets/Fonts/Cairo/Cairo-Light.ttf');
}

@font-face {
  font-family: CairoMedium;
  src: url('./assets/Fonts/Cairo/Cairo-Medium.ttf');
}

@font-face {
  font-family: CairoRegular;
  src: url('./assets/Fonts/Cairo/Cairo-Regular.ttf');
}

@font-face {
  font-family: CairoSemiBold;
  src: url('./assets/Fonts/Cairo/Cairo-SemiBold.ttf');
}

/* English Fonts */
@font-face {
  font-family: TungstenBold;
  src: url('./assets/Fonts/Tungsten/Tungsten-Bold.otf');
}

@font-face {
  font-family: TungstenBook;
  src: url('./assets/Fonts/Tungsten/Tungsten-Book.otf');
}

@font-face {
  font-family: TungstenMedium;
  src: url('./assets/Fonts/Tungsten/Tungsten-Medium.otf');
}

@font-face {
  font-family: TungstenSemiBold;
  src: url('./assets/Fonts/Tungsten/Tungsten-Semibold.otf');
}

/* DIN */
@font-face {
  font-family: DINBlack;
  src: url('./assets/Fonts/DIN/DINNextLTPro-Black.ttf');
}

@font-face {
  font-family: DINBold;
  src: url('./assets/Fonts/DIN/DINNextLTPro-Bold.ttf');
}

@font-face {
  font-family: DINLight;
  src: url('./assets/Fonts/DIN/DINNextLTPro-Light.ttf');
}

@font-face {
  font-family: DINRegular;
  src: url('./assets/Fonts/DIN/DINNextLTPro-Regular.ttf');
}

@font-face {
  font-family: DINMedium;
  src: url('./assets/Fonts/DIN/DINNextLTPro-Medium.ttf');
}

@font-face {
  font-family: GEBold;
  src: url('./assets/Fonts/GE/48-GEModernBold.otf');
}

.err {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  color: var(--text-white);
}
.Score {
    min-height: calc(100vh - 50px);
    display: flex;
    padding: 0px 100px;
    align-items: center;
}

.Score .left_result,
.Score .right_result {
    flex: 1 1 0px;
}

.Profile img {
    width: 250px;
    max-width: 100%;
}

.res_Para p {
    margin-bottom: 28px;
    font-size: 13px;
    font-weight: 400;
    text-transform: unset;
    color: var(--text-white);
    line-height: 1.6;
}

.res_ActBtns {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.instaButton .downloadImage,
.instaButton .appBtn {
    width: 100%;
}

.instaButton .appBtn a {
    margin: 0;
    position: relative;
    width: 100%;
    border: 1px solid var(--white-color);
    padding: 12px 0px;
    display: block;
    text-decoration: none;
    overflow: hidden;
    z-index: 999;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--text-white);
}

.appBtn.btn_Parent.btn_transparent {
    padding-right: 5px;
}

.res_ActBtns .btn_Parent {
    width: 80%;
}

.res_Para {
    margin-bottom: 50px;
    padding-right: 20px;
}

.profileData .gameTime span:first-child,
.profileData .hunger span:first-child,
.profileData .thirst span:first-child {
    font-size: 13px;
    font-weight: 800;
}

button {
    cursor: pointer;
}

.profileData {
    color: var(--text-white);
    top: 70%;
    left: 6px;
    position: absolute;
}

.recommendedData {
    gap: 10px;
    display: flex;
}

.canvas2 {
    position: relative;
}

.profileData .gameTime span:last-child,
.profileData .hunger span:last-child,
.profileData .thirst span:last-child {
    font-size: 14px;
}

.profileData .gameTime,
.profileData .hunger,
.profileData .thirst {
    display: flex;
    align-items: center;
    gap: 5px;
}

.custom-pagination span::after {
    cursor: pointer;
    position: relative;
    width: 10px;
    height: 10px;
    background-color: transparent;
    display: block;
    border: 1px solid #bbbbbb;
}

.custom-pagination span.answered {
    border: 1px solid var(--primary-color);
}

.custom-pagination span.answered::after {
    background-color: #ff002d !important;
}

section.modal-main .modalSection {
    background-color: var(--secondary-color);
    z-index: 9999;
    position: relative;
}

.sharePopup {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 30px;
    border: 1px solid var(--white-color);
    justify-content: center;
}

.shareButtons {
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: center;
}

.rightProfile {
    position: relative;
}

.leftInfo .heading h2 {
    color: var(--text-white);
    font-size: 27px;
    margin-bottom: 28px;
    text-transform: uppercase;
}

[dir="ltr"].leftInfo .heading h2 {
    text-align: left;
}

[dir="rtl"].leftInfo .heading h2 {
    text-align: right;
}

.sharePopup .leftInfo,
.sharePopup .rightProfile {
    flex: 1 1 0px;
}

.leftInfo .sharePara p {
    text-transform: unset;
    font-size: 13px;
    font-weight: 300;
    color: var(--text-white);
}

[dir="ltr"].leftInfo .sharePara p {
    text-align: left;
}

[dir="rtl"].leftInfo .sharePara p {
    text-align: right;
}

.leftInfo .sharePara {
    margin-bottom: 30px;
}

.shareButtons .btn_Parent button.btnContent {
    background-color: var(--secondary-color);
    border: 1px solid var(--white-color) !important;
    padding: 12px;
    font-size: 30px;
    display: flex;
    color: var(--primary-color);
    align-items: center;
}

.Profile {
    position: relative;
}

.instaPopup {
    padding: 30px;
    border: 1px solid var(--white-color);
}

.InstaContent {
    display: flex;
    align-items: center;
}

.leftInstaContent {
    margin: 0px 20px 0px 0px;
}

.instaButton {
    display: flex;
    gap: 12px;
    width: 70%;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.instaHeading h2 {
    margin-bottom: 15px;
    color: var(--white-color);
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
}

.instaHeading p ul {
    color: var(--white-color);
    padding: 0;
    list-style: number;
}

[dir="ltr"] .instaHeading p ul {
    text-align: left;
}

[dir="rtl"] .instaHeading p ul {
    text-align: right;
}

.instaHeading {
    /* width: 50%; */
    margin: 0px auto 24px;
}

.tagline {
    padding: 13px;
    border: 1px solid var(--white-color);
}

.textContent .tagline p {
    font-size: 11px;
    color: var(--text-white);
    font-weight: 300;
    text-transform: uppercase;
    text-align: left;
}

.copyBtn {
    background-color: var(--text-white);
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 7px;
    text-transform: uppercase;
}

.InstaContent .leftInstaContent {
    width: 60%;
}

.InstaContent .rightInstContent {
    width: 40%;
}

.loading {
    width: 100%;
    height: 476px;
}

[dir="ltr"] .res_Para p,
[dir="ltr"].leftInfo .sharePara p,
[dir="ltr"] .textContent .tagline p,
[dir="ltr"] .copyBtn,
[dir="ltr"].instaHeading p {
    font-family: "DINRegular";
}

[dir="ltr"].instaHeading p {
    margin-left: auto;
}

[dir="ltr"] .instaHeading h2 {
    text-align: left;
}

[dir="rtl"].instaHeading p {
    margin-right: auto;
}

[dir="rtl"] .res_Para p,
[dir="rtl"].leftInfo .sharePara p,
[dir="rtl"] .textContent .tagline p,
[dir="rtl"] .copyBtn,
[dir="rtl"].instaHeading p {
    font-family: "CairoRegular";
}

[dir="ltr"] .res_ActBtns .btn_Parent button,
[dir="ltr"] .instaButton .btn_Parent button {
    font-family: "TungstenBold";
    font-size: 24px;
}

[dir="rtl"] .res_ActBtns .btn_Parent button,
[dir="rtl"] .instaButton .btn_Parent button {
    font-family: "GEBold";
    font-size: 24px;
}

[dir="ltr"].leftInfo .heading h2,
[dir="ltr"].instaHeading h2 {
    font-family: "TungstenBold";
    font-size: 50px;
}

[dir="rtl"].leftInfo .heading h2,
[dir="rtl"].instaHeading h2 {
    font-family: "GEBold";
    font-size: 50px;
}

.instaHeading.mbinstaHeading p {
    display: flex;
    justify-content: center;
    width: unset;
}

[dir='ltr'].instaHeading p {
    justify-content: flex-start;
}

[dir='rtl'].instaHeading p {
    justify-content: center;
}

.instaHeading.mbinstaHeading {
    width: 50%;
}

.left_resultInner h1 {
    color: var(--text-white);
    letter-spacing: 1px;
    text-transform: uppercase;
}

[dir="ltr"] .left_resultInner h1 {
    font-family: "TungstenBold";
}

[dir="rtl"] .left_resultInner h1 {
    font-family: "GEBold";
}

[dir="ltr"].profileData .gameTime span.textProfile {
    text-align: left;
}

[dir="rtl"].profileData .gameTime span.textProfile {
    text-align: right;
    font-family: 'CairoRegular';
}

[dir="rtl"].profileData .recommendHeading span {
    font-family: 'CairoRegular';
}

[dir="rtl"] .gameTime span.numProfile {
    text-align: left;
}

[dir="ltr"] .gameTime span.numProfile {
    text-align: right;
}

@media (min-width: 320px) {
    .shareButtons .btn_Parent button.btnContent {
        padding: 8px;
        font-size: 15px;
    }

    .left_resultInner h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .InstaContent .leftInstaContent {
        width: 100%;
    }

    .Score {
        flex-direction: column;
        padding: 0px 60px;
    }

    .right_result,
    .rightProfile,
    .rightInstContent {
        display: none;
    }

    .mb_profile {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    .Score .left_result {
        padding: 130px 0px;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 375px) {
    .left_resultInner h1 {
        font-size: 30px;
    }

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 481px) {
    .left_resultInner h1 {
        font-size: 30px;
    }

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
    .left_resultInner h1 {
        font-size: 35px;
        margin-bottom: 20px;
    }

    .shareButtons .btn_Parent button.btnContent {
        padding: 12px;
        font-size: 30px;
    }

    .InstaContent .leftInstaContent {
        width: 60%;
    }

    .Score {
        flex-direction: row;
        padding: 0px 100px;
    }

    .right_result,
    .rightProfile,
    .rightInstContent {
        display: initial;
    }

    .mb_profile {
        display: none;
        margin-bottom: 0px;
    }

    .Score .left_result {
        padding: 0px;
    }

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
    .left_resultInner h1 {
        font-size: 40px;
    }

    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
    .left_resultInner h1 {
        font-size: 40px;
    }

    /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
    .left_resultInner h1 {
        font-size: 45px;
    }

    /* hi-res laptops and desktops */
}

/* Shamrin Css */

.right_result {
    position: relative;
}

.cardCanvas .Profile>img {
    width: 100%;
    display: block;
    transition: 0.6s ease;
}

.Score.resultScoreWidth {
    margin: 0 auto;
    background: transparent;
    padding: 10% 0;
    max-width: 90%;
    width: 100%;
    padding: 170px 0;
}

.canvas2.cardCanvas {
    position: relative;
    /* margin: 10px 40px; */
    padding: 10% 0px;
    width: calc(100% - 80px);
    margin: 10px auto;
}

.cardCanvas .Profile {
    position: relative;
    border: 1px solid #e3dbd3;
}

.cardCanvas .Profile:before {
    content: "";
    position: absolute;
    top: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-bottom: 0px;
}

.cardCanvas .Profile:after {
    content: "";
    position: absolute;
    bottom: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-top: 0px;
}

[dir="ltr"] p ul li {
    text-align: left;
}

[dir="rtl"] p ul li {
    text-align: right;
}

.cardCanvas .profileData {
    background: rgb(0 0 0 / 52%);
    padding: 10px;
    width: 84.6%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.cardCanvas .profileData .profileDetails {
    width: 32%;
    display: flex;
    justify-content: space-between;
}

.cardCanvas span.textProfile {
    width: 50%;
    font-size: 10.4px !important;
    text-transform: uppercase;
    font-family: DINLight;
}

.cardCanvas span.numProfile {
    font-size: 25.28px !important;
    line-height: 1;
    width: 45%;
    font-family: DINBold;
    text-align: right;
}

.cardCanvas .profileQuizTitle {
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78.2%;
    text-align: center;
    z-index: 9;
}

.cardCanvas .profileQuizTitle h2.profileHead {
    z-index: 9;
    position: relative;
    font-weight: 800;
    font-size: 26.4px;
    display: inline-block;
    padding-top: 7px;
    line-height: 1;
    font-family: TungstenBold;
    color: #101924;
}

.cardCanvas .profileQuizTitle span.bgColor {
    position: absolute;
    left: 50%;
    height: 0px;
    width: 85%;
    border-bottom: 39px solid #eaeeb2;
    border-left: 39px solid transparent;
    border-right: 39px solid transparent;
    transform: translateX(-50%);
    top: 0;
}

.modalWrapCustom .modalOverlay {
    background-color: rgb(3 4 6 / 88%);
}

.Profile.loaded {
    opacity: 1;
}

.cardCanvas .Profile>img.loading {
    opacity: 0;
}

.instaHeading.mbinstaHeading {
    display: none;
}

@media (min-width: 1680px) {
    .modalWrapCustom section.modal-main {
        max-width: 1380px;
    }

    .sharePopup {
        gap: 40px;
    }
}

@media (min-width: 1440px) {
    .sharePopup {
        gap: 40px;
    }
}

@media (min-width: 2561px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }

    .cardCanvas .profileData {
        width: 87%;
    }

    .cardCanvas span.numProfile {
        font-size: 102px !important;
        margin-top: 13px;
    }

    .cardCanvas span.textProfile {
        font-size: 20.8px !important;
    }
}

@media (max-width: 2560px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }

    .cardCanvas .profileData {
        width: 87%;
    }

    .cardCanvas span.numProfile {
        font-size: 102px !important;
        margin-top: 13px;
    }

    .cardCanvas span.textProfile {
        font-size: 20.8px !important;
    }
}

@media (max-width: 1920px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 88%;
    }

    .cardCanvas .profileData {
        width: 86%;
    }

    .cardCanvas span.textProfile {
        font-size: 15px !important;
    }

    .cardCanvas span.numProfile {
        font-size: 72px !important;
        margin-top: 13px;
    }
}

@media (max-width: 1680px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 26.4px;
        padding-top: 7px;
    }

    .cardCanvas .profileData {
        width: 85.5%;
    }

    .cardCanvas span.numProfile {
        font-size: 58px !important;
        margin-top: 7px;
    }

    .cardCanvas span.textProfile {
        font-size: 12px !important;
    }
}

@media (max-width: 1600px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }

    .sharePopup.mobileRev.padLess01 {
        padding: 40px 0;
    }
}

@media (max-width: 1679px) {

    .padLess01 .leftInfo,
    .padLess01 .rightProfile.buttonPopup {
        width: 45%;
        flex: unset;
    }

    .sharePopup.mobileRev.padLess01 {
        padding: 5% 40px;
    }
}

@media (max-width: 1536px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 87%;
    }
}

@media (max-width: 1440px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 86%;
    }

    .cardCanvas .profileQuizTitle {
        top: 12.5%;
        width: 77.8%;
    }

    .cardCanvas .profileData {
        width: 85%;
    }

    .cardCanvas span.numProfile {
        font-size: 42px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 10.4px !important;
    }
}

@media (max-width: 1366px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 85%;
    }
}

@media (max-width: 1280px) {
    .Score.resultScoreWidth {
        padding: 10% 5%;
    }

    .Score.resultScoreWidth {
        padding: 100px 0;
        max-width: 95%;
    }

    .canvas2.cardCanvas {
        padding: 0% 10px;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 20.8px;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        border-bottom: 32px solid #eaeeb2;
        border-left: 32px solid transparent;
        border-right: 32px solid transparent;
        width: 85.5%;
    }

    .cardCanvas .profileData {
        width: 84.5%;
    }

    .cardCanvas span.numProfile {
        font-size: 35px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 8.8px !important;
    }
}

@media (max-width: 1160px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 83.5%;
    }

    .cardCanvas .profileData {
        width: 84%;
    }

    .cardCanvas span.numProfile {
        font-size: 30px !important;
        width: 40%;
    }

    .cardCanvas span.textProfile {
        font-size: 8.48px !important;
        width: 50%;
    }
}

@media (max-width: 1024px) {
    .canvas2.cardCanvas {
        margin: 10px 0;
        width: 100%;
        padding: 0;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        width: 85.5%;
        border-bottom: 28px solid #eaeeb2;
        border-left: 28px solid transparent;
        border-right: 28px solid transparent;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 16px;
        padding-top: 7px;
    }
}

@media (max-width: 814px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 83.5%;
    }

    .leftInstaContent {
        margin: 40px 0px 0px 0px;
    }

    .instaHeading.mbinstaHeading {
        display: block;
    }

    .instaHeading {
        display: none;
    }

    .instaPopupSh .instaHeading p {
        justify-content: flex-start;
    }

    .instaButton {
        width: 80%;
    }

    .cardCanvas .profileData {
        width: 82.5%;
    }

    .cardCanvas span.numProfile {
        font-size: 22px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 6.4px !important;
    }
}

@media (max-width: 768px) {

    .Score.resultScoreWidth .left_result,
    .Score.resultScoreWidth .right_result {
        flex: unset;
        width: 100%;
    }

    .Score.resultScoreWidth {
        flex-flow: wrap;
    }

    .canvas2.cardCanvas {
        margin: 10px 0px;
    }

    .cardCanvas .profileQuizTitle {
        top: 13%;
        width: 79%;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        width: 89.5%;
    }

    .cardCanvas .profileData {
        width: 86%;
    }

    .cardCanvas span.textProfile {
        font-size: 13.12px !important;
    }

    .cardCanvas span.numProfile {
        font-size: 22.4px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 16px !important;
        width: 60%;
    }

    .cardCanvas .profileData .profileDetails {
        width: 33%;
        gap: 0;
    }

    .cardCanvas span.numProfile {
        font-size: 40px !important;
        width: 35%;
    }

    .Score.resultScoreWidth {
        max-width: 90%;
    }

    .Score.resultScoreWidth .right_result {
        margin-top: 20px;
    }

    .padLess01 .leftInfo,
    .padLess01 .rightProfile.buttonPopup {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .cardCanvas .profileQuizTitle {
        top: 12.5%;
        width: 78.5%;
    }

    .cardCanvas .profileData {
        width: 85.5%;
    }
}

@media (max-width: 680px) {
    .cardCanvas .profileQuizTitle {
        width: 77.5%;
    }

    .cardCanvas .profileData {
        width: 85.2%;
    }

    .cardCanvas span.numProfile {
        font-size: 35px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 13px !important;
    }
}

@media (max-width: 640px) {
    .Score.resultScoreWidth {
        padding: 80px 0 60px;
    }
}

@media (max-width: 580px) {
    .cardCanvas .profileQuizTitle {
        width: 77%;
    }

    .cardCanvas .profileData {
        width: 84.2%;
    }

    .cardCanvas span.textProfile {
        font-size: 11px !important;
    }

    .cardCanvas span.numProfile {
        font-size: 27px !important;
        margin-top: 2px;
    }
}

@media (max-width: 500px) {
    .cardCanvas .profileQuizTitle {
        width: 76.5%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        font-size: 13.6px;
        padding-top: 7px;
    }

    .cardCanvas span.textProfile {
        font-size: 10px !important;
    }

    .cardCanvas span.numProfile {
        font-size: 20px !important;
    }
}

@media (max-width: 480px) {
    .cardCanvas .profileQuizTitle {
        width: 76%;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        border-bottom: 22px solid #eaeeb2;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        width: 89.5%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        padding-top: 6px;
    }

    .cardCanvas .profileQuizTitle {
        width: 77.2%;
        top: 13%;
    }
}

@media (max-width: 440px) {
    .cardCanvas .profileData {
        width: 83.2%;
    }

    .cardCanvas span.textProfile {
        font-size: 8px !important;
    }

    .cardCanvas span.numProfile {
        font-size: 18px !important;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        width: 88.5%;
    }
}

@media (max-width: 390px) {
    .cardCanvas .profileQuizTitle span.bgColor {
        width: 87.5%;
    }

    .cardCanvas .profileQuizTitle h2.profileHead {
        padding-top: 5px;
    }

    .cardCanvas .profileData {
        width: 88%;
        gap: 0;
        padding-left: 2px;
        padding-right: 2px;
    }

    .cardCanvas span.numProfile {
        font-size: 17px !important;
    }

    .cardCanvas span.textProfile {
        font-size: 7px !important;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        width: 89%;
    }
}

@media (max-width: 360px) {
    .cardCanvas .profileQuizTitle {
        width: 76.5%;
    }
}

@media (max-width: 340px) {
    .cardCanvas .profileQuizTitle {
        width: 75.5%;
    }

    .cardCanvas .profileQuizTitle span.bgColor {
        width: 90%;
    }
}

@media (max-width: 320px) {
    .instaButton {
        width: 80%;
    }

    .cardCanvas .profileQuizTitle {
        width: 74.5%;
    }

    .cardCanvas .profileData {
        width: 87%;
    }
}

.buttonPopup {
    position: relative;
}

.buttonPopup .Profile>img {
    width: 100%;
    display: block;
}

.buttonPopup .Profile {
    position: relative;
    border: 1px solid #e3dbd3;
}

.buttonPopup .Profile:before {
    content: "";
    position: absolute;
    top: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-bottom: 0px;
}

.buttonPopup .Profile:after {
    content: "";
    position: absolute;
    bottom: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-top: 0px;
}

.buttonPopup .profileData {
    background: rgb(0 0 0 / 52%);
    padding: 10px 10px 0px 10px;
    width: 84.6%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.buttonPopup .recommendedData {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.buttonPopup .profileData .profileDetails {
    width: 32%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    text-align: left;
}

.buttonPopup span.textProfile {
    width: 50%;
    font-size: 10.4px !important;
    text-transform: uppercase;
    font-family: DINRegular;
    margin-bottom: 7px;
    line-height: 1;
}

.buttonPopup span.numProfile {
    font-size: 25.28px !important;
    line-height: 1;
    width: 45%;
    font-family: DINBold;
    text-align: right;
}

.buttonPopup .profileQuizTitle {
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78.2%;
    text-align: center;
    z-index: 9;
}

.buttonPopup .profileQuizTitle h2.profileHead {
    z-index: 9;
    position: relative;
    font-weight: 800;
    font-size: 26.4px;
    display: inline-block;
    padding-top: 7px;
    line-height: 1;
    text-transform: uppercase;
    color: #101924;
}

/* .mainDiv.ltr .buttonPopup .profileQuizTitle h2.profileHead,
.mainDiv.ltr .instaSharePopup .profileQuizTitle h2.profileHead {
    font-family: TungstenBold;
}

.mainDiv.rtl .buttonPopup .profileQuizTitle h2.profileHead,
.mainDiv.rtl .instaSharePopup .profileQuizTitle h2.profileHead {
    font-family: 'CairoRegular';
} */
.mainDiv.rtl .canvas2.buttonPopup .profileQuizTitle h2.profileHead,.mainDiv.rtl .buttonPopup .profileQuizTitle h2.profileHead,.mainDiv.rtl .instaSharePopup .profileQuizTitle h2.profileHead  {
    font-family: 'GEBold';
}

.left_resultInner h1 span {
    font-family: 'TungstenBold';
}

.buttonPopup .profileQuizTitle h2.profileHead,
.instaSharePopup .profileQuizTitle h2.profileHead {
    font-family: TungstenBold;
}

.buttonPopup .profileQuizTitle span.bgColor {
    position: absolute;
    left: 50%;
    height: 0px;
    width: 85%;
    border-bottom: 39px solid #eaeeb2;
    border-left: 39px solid transparent;
    border-right: 39px solid transparent;
    transform: translateX(-50%);
    top: 0;
}

.sharePopup.mobileRev.padLess01 {
    padding: 5% 40px;
}

@media (min-width: 2561px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 88%;
    }

    .buttonPopup .profileData {
        width: 86%;
    }

    .buttonPopup span.textProfile {
        font-size: 13px !important;
    }

    .buttonPopup span.numProfile {
        font-size: 63px !important;
        margin-top: 0px;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }
}

@media (max-width: 2560px) {
    /* .buttonPopup .profileQuizTitle span.bgColor {
        width: 90%;
    }    
    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }  
    .buttonPopup .profileData {
        width: 87%;
    }
    .buttonPopup span.numProfile {
        font-size: 60px !important;
        margin-top: 13px;
    }
    .buttonPopup span.textProfile {
        font-size: 16px !important;
    } */
}

@media (max-width: 2560px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 88%;
    }

    .buttonPopup .profileData {
        width: 88%;
    }

    .buttonPopup span.textProfile {
        font-size: 16px !important;
    }

    .buttonPopup span.numProfile {
        font-size: 63px !important;
        margin-top: 0px;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }
}

@media (max-width: 1680px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 87.5%;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 26.4px;
        padding-top: 7px;
    }

    .buttonPopup .profileData {
        width: 85.5%;
    }

    .buttonPopup span.numProfile {
        font-size: 53px !important;
        margin-top: 0px;
    }

    .buttonPopup span.textProfile {
        font-size: 13px !important;
        margin-bottom: 3px;
    }

    .buttonPopup .profileData {
        padding: 9px 10px 0px 10px;
    }
}

@media (max-width: 1600px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 87.5%;
    }
}

@media (max-width: 1536px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 87%;
    }

    .sharePopup.mobileRev.padLess01 {
        padding: 30px;
    }

    .buttonPopup span.textProfile {
        font-size: 12px !important;
        margin-bottom: 6px;
    }

    .buttonPopup span.numProfile {
        font-size: 55px !important;
    }
}

@media (max-width: 1440px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 86%;
    }

    .buttonPopup .profileQuizTitle {
        top: 12.5%;
        width: 76.8%;
    }

    .buttonPopup .profileData {
        width: 87.5%;
    }

    .buttonPopup span.numProfile {
        font-size: 50px !important;
    }

    .buttonPopup span.textProfile {
        font-size: 10.4px !important;
        margin-bottom: 4px;
    }

    .buttonPopup .profileData {
        padding: 9px 10px 0px 10px;
    }
}

@media (max-width: 1366px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 85%;
    }

    .buttonPopup span.textProfile {
        font-size: 10px !important;
        margin-bottom: 4px;
    }

    /* .buttonPopup span.numProfile {
        font-size: 45px !important;
    } */
    .sharePopup.mobileRev.padLess01 {
        padding: 30px;
    }

    .buttonPopup .profileData {
        padding: 7px 10px 0px 10px;
    }
}

@media (max-width: 1280px) {
    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 20.8px;
    }

    .buttonPopup .profileQuizTitle span.bgColor {
        border-bottom: 32px solid #eaeeb2;
        border-left: 32px solid transparent;
        border-right: 32px solid transparent;
        width: 85.5%;
    }

    .buttonPopup .profileData {
        width: 84.5%;
    }

    .buttonPopup span.numProfile {
        font-size: 40px !important;
    }

    .buttonPopup span.textProfile {
        font-size: 8.8px !important;
        margin-bottom: 3px;
    }
}

@media (max-width: 1200px) {
    .buttonPopup .profileData {
        width: 84%;
    }
}

@media (max-width: 1160px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 83.5%;
    }

    .buttonPopup .profileData {
        width: 86%;
    }

    /* .buttonPopup span.numProfile {
        font-size: 32px !important;
        width: 40%;
        margin-top: 2px;
    } */
    .buttonPopup span.textProfile {
        font-size: 8.48px !important;
        width: 55%;
        margin-bottom: 1px;
    }

    .buttonPopup .profileData {
        padding: 4px 10px 0px 10px;
    }
}

@media (max-width: 1024px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 85.5%;
        border-bottom: 28px solid #eaeeb2;
        border-left: 28px solid transparent;
        border-right: 28px solid transparent;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 16px;
        padding-top: 7px;
    }

    .buttonPopup span.textProfile {
        font-size: 8px !important;
    }
}

@media (max-width: 991px) {
    .buttonPopup .profileData {
        width: 86%;
    }

    .buttonPopup span.textProfile {
        font-size: 7px !important;
    }
}

@media (max-width: 814px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 83.5%;
    }

    .buttonPopup .profileData {
        width: 81%;
    }

    .buttonPopup span.numProfile {
        font-size: 20px !important;
        margin-top: 0px;
    }

    .buttonPopup span.textProfile {
        font-size: 6px !important;
        margin-bottom: 0px;
    }

    .buttonPopup .profileData {
        padding: 3px 10px 0px 10px;
    }
}

@media (max-width: 768px) {
    .buttonPopup .profileQuizTitle {
        top: 13%;
        width: 79%;
    }

    .buttonPopup .profileQuizTitle span.bgColor {
        width: 88%;
    }

    .buttonPopup .profileData {
        width: 88%;
    }

    .buttonPopup span.textProfile {
        font-size: 13.12px !important;
    }

    .buttonPopup span.numProfile {
        font-size: 22.4px !important;
    }

    .buttonPopup span.textProfile {
        font-size: 13px !important;
        width: 60%;
        margin-bottom: 3px;
    }

    .buttonPopup .profileData .profileDetails {
        width: 33%;
        gap: 0;
    }

    .buttonPopup span.numProfile {
        font-size: 45px !important;
        width: 35%;
    }

    .sharePopup.mobileRev {
        flex-flow: column-reverse;
    }

    .buttonPopup .profileData {
        padding: 12px 10px 0px 10px;
    }

    .buttonPopup span.textProfile {
        margin-bottom: 8px;
    }
}

@media (max-width: 767px) {
    .buttonPopup .profileQuizTitle {
        top: 12.5%;
        width: 78.5%;
    }

    .buttonPopup .profileData {
        width: 85.1%;
    }
}

@media (max-width: 680px) {
    .buttonPopup .profileQuizTitle {
        width: 77.5%;
    }

    .buttonPopup .profileData {
        width: 84.7%;
    }

    /* .buttonPopup span.numProfile {
        font-size: 28px !important; 
    } */
    .buttonPopup span.textProfile {
        font-size: 11px !important;
    }
}

@media (max-width: 640px) {
    .rightProfile.buttonPopup {
        display: block !important;
    }

    .buttonPopup span.textProfile {
        font-size: 12px !important;
    }

    section.modal-main {
        max-width: 93% !important;
    }

    .sharePopup.mobileRev.padLess01 {
        padding: 20px;
    }

    section.modal-main:before {
        width: 98% !important;
        right: 0px;
        height: 40% !important;
        transform: translateX(-50%);
        left: 50%;
    }

    section.modal-main:after {
        width: 98% !important;
        height: 40% !important;
        transform: translateX(-50%);
        left: 50%;
    }

    .buttonPopup .profileData {
        width: 85%;
    }
}

@media (max-width: 580px) {
    .buttonPopup .profileQuizTitle {
        width: 77%;
    }

    .buttonPopup .profileData {
        width: 84%;
    }

    .buttonPopup span.textProfile {
        font-size: 11px !important;
    }

    .buttonPopup span.numProfile {
        /* font-size: 27px !important; */
        margin-top: 2px;
    }

    .buttonPopup .profileData {
        padding: 5px 10px 3px 10px;
    }

    /* .buttonPopup span.textProfile {
        margin-bottom: 5px;
    } */
    .Score.resultContainer .mb_profile {
        max-width: 90%;
    }

    .buttonPopup span.numProfile {
        font-size: 50px !important;
    }

    .buttonPopup span.textProfile {
        margin-bottom: 5px;
    }

    .buttonPopup .profileData {
        padding: 6px 10px 0px 10px;
    }
}

@media (max-width: 500px) {
    .buttonPopup .profileQuizTitle {
        width: 76.5%;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        font-size: 13.6px;
        padding-top: 7px;
    }

    .buttonPopup span.textProfile {
        font-size: 10px !important;
    }

    /*.buttonPopup span.numProfile {
         font-size: 20px !important; 
    } */
    /* .buttonPopup span.numProfile {
        font-size: 30px !important;
        margin-top: 1px;
    }
    .buttonPopup span.textProfile {
        margin-bottom: 2px;
    } */
}

@media (max-width: 480px) {
    .buttonPopup .profileQuizTitle {
        width: 76%;
    }

    .buttonPopup .profileQuizTitle span.bgColor {
        border-bottom: 22px solid #eaeeb2;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        width: 88.5%;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        padding-top: 6px;
    }

    .buttonPopup .profileQuizTitle {
        width: 77.2%;
        top: 13%;
    }

    .buttonPopup span.numProfile {
        margin-top: 0px;
    }

    .buttonPopup span.numProfile {
        font-size: 45px !important;
    }

    .sharePopup.mobileRev.padLess01 {
        padding: 15px;
    }

    .buttonPopup .profileData {
        width: 83.5%;
    }

    .buttonPopup span.textProfile {
        font-size: 8px !important;
    }

    /* .buttonPopup span.numProfile {
         font-size: 28px !important; 
    } */
}

@media (max-width: 440px) {
    .buttonPopup .profileData {
        width: 84.2%;
    }

    .buttonPopup span.textProfile {
        font-size: 8px !important;
    }

    .buttonPopup span.numProfile {
        font-size: 26px !important;
    }
}

@media (max-width: 390px) {
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 87.5%;
    }

    .buttonPopup .profileQuizTitle h2.profileHead {
        padding-top: 5px;
    }

    .buttonPopup .profileData {
        width: 90%;
        gap: 0;
        padding-left: 2px;
        padding-right: 2px;
    }

    /* .buttonPopup span.numProfile {
        font-size: 24px !important;
    } */
    .buttonPopup span.textProfile {
        font-size: 8px !important;
    }
}

@media (max-width: 360px) {
    .buttonPopup .profileQuizTitle {
        width: 76.5%;
    }

    .buttonPopup span.textProfile {
        font-size: 7px !important;
        margin-bottom: 4px;
    }

    .buttonPopup span.numProfile {
        font-size: 20px !important;
    }

    .buttonPopup .profileData {
        width: 87.5%;
    }
}

@media (max-width: 340px) {
    .buttonPopup .profileQuizTitle {
        width: 75.5%;
    }

    .buttonPopup .profileData {
        width: 87%;
    }
}

@media (max-width: 320px) {
    .buttonPopup .profileQuizTitle {
        width: 74.5%;
    }

    .buttonPopup .profileData {
        width: 90%;
    }

    /* .buttonPopup span.numProfile {
        font-size: 20px !important;
    } */
    .buttonPopup .profileQuizTitle span.bgColor {
        width: 88%;
    }
}

.instaSharePopup {
    position: relative;
}

.instaSharePopup .Profile>img {
    width: 100%;
    display: block;
}

.instaSharePopup .Profile {
    position: relative;
    border: 1px solid #e3dbd3;
}

.instaSharePopup .Profile:before {
    content: "";
    position: absolute;
    top: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-bottom: 0px;
}

.instaSharePopup .Profile:after {
    content: "";
    position: absolute;
    bottom: -1.5%;
    left: -1.5%;
    width: 103%;
    height: 44%;
    border: 1px solid #e3dbd3;
    border-top: 0px;
}

.instaSharePopup .profileData {
    background: rgb(0 0 0 / 52%);
    padding: 10px;
    width: 84.6%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.instaSharePopup .recommendedData {
    display: flex;
    justify-content: center;
}

.recommendHeading {
    display: flex;
    margin: 3px 0px 5px 0px;
    justify-content: center;
}

.instaSharePopup .recommendHeading {
    margin: 4px 0px 5px 0px;
}

.recommendHeading>span.textProfile {
    text-align: center;
    width: 100% !important;
}

.instaSharePopup .profileData .profileDetails {
    width: 32%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    text-align: left;
}

.instaSharePopup span.textProfile {
    width: 50%;
    font-size: 10.4px !important;
    text-transform: uppercase;
    font-family: DINRegular;
    line-height: 1;
    margin-bottom: 4px;
}

.instaSharePopup span.numProfile {
    font-size: 25.28px !important;
    line-height: 1;
    width: 45%;
    font-family: DINBold;
    text-align: right;
}

.instaSharePopup .profileQuizTitle {
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78.2%;
    text-align: center;
    z-index: 9;
}

.instaSharePopup .profileQuizTitle h2.profileHead {
    z-index: 9;
    position: relative;
    font-weight: 800;
    font-size: 26.4px;
    display: inline-block;
    padding-top: 7px;
    text-transform: uppercase;
    line-height: 1;
    color: #101924;
}

.instaSharePopup .profileQuizTitle span.bgColor {
    position: absolute;
    left: 50%;
    height: 0px;
    width: 85%;
    border-bottom: 39px solid #eaeeb2;
    border-left: 39px solid transparent;
    border-right: 39px solid transparent;
    transform: translateX(-50%);
    top: 0;
}

.mobileRev.padLess02 {
    padding: 5% 40px;
}

.InstaContent.instaShareCol {
    justify-content: space-between;
}

.InstaContent.instaShareCol .leftInstaContent {
    width: 55%;
}

@media (min-width: 2561px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84%;
    }

    .instaSharePopup .profileData {
        width: 85.5%;
    }

    .instaSharePopup span.textProfile {
        font-size: 10px !important;
    }

    .instaSharePopup span.numProfile {
        font-size: 50px !important;
        margin-top: 0px;
    }

    .InstaContent .rightInstContent {
        max-width: 386px;
    }

    .InstaContent.instaShareCol {
        justify-content: space-evenly;
        margin-top: 30px;
    }

    .instaPopup.instaPopupSh {
        padding: 40px;
    }

    .instaSharePopup .profileData {
        padding: 10px 10px 5px 10px;
    }
}

/* @media (max-width:2560px) { 
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 89%;
    }    
    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 31.2px;
        padding-top: 4px;
    }  
    .instaSharePopup .profileData {
        width: 86%;
    }
    .instaSharePopup span.numProfile {
        font-size: 60px !important;
        margin-top: 13px;
    }
    .instaSharePopup span.textProfile {
        font-size: 16px !important;
    }
} */
@media (max-width: 2560px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84%;
    }

    .instaSharePopup .profileData {
        width: 85.5%;
    }

    .instaSharePopup span.textProfile {
        font-size: 10px !important;
    }

    .instaSharePopup span.numProfile {
        font-size: 50px !important;
        margin-top: 0px;
    }

    .InstaContent .rightInstContent {
        max-width: 386px;
    }

    .InstaContent.instaShareCol {
        justify-content: space-evenly;
    }

    .instaPopup.instaPopupSh {
        padding: 40px;
    }

    .instaSharePopup .profileData {
        padding: 10px 10px 5px 10px;
    }
}

@media (max-width: 1680px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84%;
    }

    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 26.4px;
        padding-top: 7px;
    }

    .instaSharePopup .profileData {
        width: 84.5%;
    }

    .instaSharePopup span.numProfile {
        font-size: 50px !important;
        margin-top: 5px;
    }

    .instaSharePopup span.textProfile {
        font-size: 10px !important;
    }

    .instaSharePopup .profileData {
        padding: 2px 10px 0px 10px;
    }

    .instaSharePopup span.textProfile {
        margin-bottom: -1px;
    }

    /* }
@media (max-width: 1600px){ */
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 85.5%;
    }

    .InstaContent .rightInstContent {
        max-width: unset;
    }

    .InstaContent .rightInstContent {
        width: 45%;
    }

    /* }
@media (max-width: 1536px){
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84.5%;
    }
}
@media (max-width: 1440px){ */
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 83%;
    }

    .instaSharePopup .profileQuizTitle {
        top: 12.5%;
        width: 77.8%;
    }

    .instaSharePopup .profileData {
        width: 83.5%;
    }

    .instaSharePopup span.numProfile {
        font-size: 50px !important;
    }

    .instaSharePopup span.textProfile {
        font-size: 10px !important;
    }
}

@media (max-width: 1536px) {
    .instaSharePopup span.textProfile {
        font-size: 10px !important;
    }

    .instaSharePopup span.numProfile {
        font-size: 50px !important;
    }
}

@media (max-width: 1440px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 83%;
    }

    .instaSharePopup span.textProfile {
        font-size: 8px !important;
    }

    .instaSharePopup span.numProfile {
        font-size: 24px !important;
    }

    .mobileRev.padLess02 {
        padding: 30px;
    }

    .instaSharePopup .profileData {
        width: 84%;
    }

    .instaSharePopup .profileData {
        padding: 6px 10px 0px 10px;
    }

    .instaSharePopup span.textProfile {
        margin-bottom: 2px;
    }
}

@media (max-width: 1440px) {
    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 20.8px;
    }

    .instaSharePopup .profileQuizTitle span.bgColor {
        border-bottom: 32px solid #eaeeb2;
        border-left: 32px solid transparent;
        border-right: 32px solid transparent;
        width: 82.5%;
    }

    .instaSharePopup .profileData {
        width: 87.5%;
    }

    .instaSharePopup span.numProfile {
        font-size: 32px !important;
        margin-top: 0px;
    }

    .instaSharePopup span.textProfile {
        font-size: 8px !important;
    }

    .instaSharePopup .profileData .profileDetails {
        width: 33%;
    }
}

@media (max-width: 1366px) {
    .instaSharePopup span.textProfile {
        font-size: 7px !important;
    }

    .instaSharePopup span.numProfile {
        font-size: 32px !important;
    }

    .instaSharePopup .profileData {
        padding: 3px 10px 0px 10px;
    }
}

@media (max-width: 1280px) {
    .instaSharePopup span.textProfile {
        margin-bottom: 1px;
    }
}

@media (max-width: 1200px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 83.5%;
    }

    .instaSharePopup .profileData {
        width: 82.5%;
    }

    .instaSharePopup span.numProfile {
        font-size: 39px !important;
        width: 40%;
        margin-top: 3px;
    }

    .instaSharePopup span.textProfile {
        font-size: 8px !important;
        width: 55%;
    }

    .instaSharePopup .profileQuizTitle {
        width: 77%;
    }

    .InstaContent .rightInstContent {
        width: 40%;
    }

    .instaSharePopup .profileData {
        padding: 2px 10px 0px 10px;
    }
}

@media (max-width: 1024px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 85.5%;
        border-bottom: 28px solid #eaeeb2;
        border-left: 28px solid transparent;
        border-right: 28px solid transparent;
    }

    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 16px;
        padding-top: 7px;
    }

    .instaSharePopup span.textProfile {
        font-size: 7px !important;
    }

    .instaSharePopup .profileQuizTitle {
        width: 75%;
    }

    .instaSharePopup .profileData {
        width: 84.5%;
    }

    .instaSharePopup span.numProfile {
        font-size: 28px !important;
    }

    .instaSharePopup span.textProfile {
        margin-bottom: 0px;
    }
}

/* @media (max-width: 814px) {
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 83.5%;
    }
    .instaSharePopup .profileData {
        width: 79.8%;
    }
    .instaSharePopup span.numProfile {
        font-size: 16px !important;
        margin-top: 0px;
    }
    .instaSharePopup span.textProfile {
        font-size: 4px !important;
    }

} */
@media (max-width: 814px) {

    /* .instaSharePopup .profileQuizTitle {
        top: 13%;
        width: 79%;
    }
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 88.5%;
    }
    .instaSharePopup .profileData {
        width: 85%;
    }
    .instaSharePopup span.textProfile {
        font-size: 13.12px !important;
    }
    .instaSharePopup span.numProfile {
        font-size: 22.4px !important;
    }
    .instaSharePopup span.textProfile {
        font-size: 12px !important;
        width: 60%;
    }
    .instaSharePopup .profileData .profileDetails {
        width: 33%;
        gap: 0;
    }
    .instaSharePopup span.numProfile {
        font-size: 26px !important;
        width: 35%;
    } */
    .InstaContent.instaShareCol {
        flex-flow: column-reverse;
        justify-content: space-between;
    }

    .InstaContent.instaShareCol .leftInstaContent {
        width: 100%;
    }

    .InstaContent .rightInstContent {
        width: 100%;
        display: block !important;
    }

    .instaSharePopup .Profile {
        max-width: 320px;
        margin: 0 auto;
    }

    .instaSharePopup span.textProfile {
        font-size: 9px !important;
        margin-bottom: 2px;
    }

    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 87%;
    }

    .instaSharePopup span.numProfile {
        font-size: 25px !important;
        margin-top: 4px;
    }
}

@media (max-width: 680px) {
    /* .instaSharePopup span.numProfile {
        font-size: 28px !important;
        margin-top: 2px;
    } */
}

@media (max-width: 580px) {
    .instaSharePopup .profileQuizTitle {
        width: 80.5%;
    }

    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84%;
        border-bottom: 20px solid #eaeeb2;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
    }

    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 11px;
        padding-top: 0px;
    }
}

@media (max-width: 475px) {
    .instaPopup.instaPopupSh {
        padding: 15px;
    }

    .instaSharePopup .profileData {
        width: 84.5%;
    }
}

@media (max-width: 390px) {
    .InstaContent .rightInstContent {
        width: 95%;
        margin: 0 auto;
    }

    .instaSharePopup .profileData {
        width: 88%;
        padding: 5px;
    }
}

@media (max-width: 360px) {
    .instaSharePopup .profileQuizTitle {
        width: 73.5%;
    }

    .instaSharePopup span.textProfile {
        font-size: 7px !important;
    }

    /* .instaSharePopup span.numProfile {
        font-size: 22px !important;
    } */
    .instaSharePopup .profileData {
        width: 85%;
    }

    .instaSharePopup .profileQuizTitle {
        width: 77.5%;
    }
}

@media (max-width: 340px) {
    .instaSharePopup .profileData {
        width: 84.2%;
    }

    .instaSharePopup span.textProfile {
        font-size: 6px !important;
    }

    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 84%;
    }

    /* .instaSharePopup span.numProfile {
        font-size: 20px !important;
        margin-top: 3px;
    } */
}

@media (max-width: 320px) {
    .instaSharePopup .profileData {
        width: 85%;
    }

    /* .instaSharePopup span.numProfile {
        font-size: 20px !important;
        margin-top: 3px;
    } */
}

/* 

@media (max-width: 767px){
    .instaSharePopup .profileQuizTitle {
        top: 12.5%;
        width: 78.5%;
    }
    .instaSharePopup .profileData {
        width: 85.1%;
    }
}
@media (max-width: 680px){
    .instaSharePopup .profileQuizTitle {
        width: 77.5%;
    }
    .instaSharePopup .profileData {
        width: 84.7%;
    }
    .instaSharePopup span.numProfile {
        font-size: 25px !important;
    }
    .instaSharePopup span.textProfile {
        font-size: 11px !important;
    }
}
@media (max-width: 640px) {
    .rightInstContent.instaSharePopup {
        display: block !important;
    }
    .instaSharePopup span.textProfile {
        font-size: 12px !important;
    }
    .sharePopup.mobileRev.padLess02 {
        padding: 20px;
    }
    .instaSharePopup .profileData {
        width: 85%;
    }
}
@media (max-width: 580px){
    .instaSharePopup .profileQuizTitle {
        width: 77%;
    }
    .instaSharePopup .profileData {
        width: 84%;
    }
    .instaSharePopup span.textProfile {
        font-size: 11px !important;
    }
    .instaSharePopup span.numProfile {
        font-size: 27px !important;
        margin-top: 2px;
    }   
    
}
@media (max-width: 500px){
    .instaSharePopup .profileQuizTitle {
        width: 76.5%;
    }
    .instaSharePopup .profileQuizTitle h2.profileHead {
        font-size: 13.6px;
        padding-top: 7px;
    }
    .instaSharePopup span.textProfile {
        font-size: 10px!important;
    }
    .instaSharePopup span.numProfile {
        font-size: 20px !important;
    } 
}
@media (max-width: 480px){
    .instaSharePopup .profileQuizTitle {
        width: 76%;
    }
    .instaSharePopup .profileQuizTitle span.bgColor {
        border-bottom: 22px solid #eaeeb2;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        width: 88.5%;
    }
    .instaSharePopup .profileQuizTitle h2.profileHead {
        padding-top: 6px;
    }
    .instaSharePopup .profileQuizTitle {
        width: 77.2%;
        top: 13%;
    }
    .instaSharePopup span.numProfile {
        margin-top: 0px;
    }
    .instaSharePopup span.numProfile {
        font-size: 20px !important;
    }
    .sharePopup.mobileRev.padLess02 {
        padding: 15px;
    }
    .instaSharePopup .profileData {
        width: 83.5%;
    }
}
@media (max-width: 440px) {
    
    .instaSharePopup .profileData {
        width: 83.2%;
    }
    
    .instaSharePopup span.textProfile {
        font-size: 8px!important;
    }
    .instaSharePopup span.numProfile {
        font-size: 17px !important;
    }
}

@media (max-width: 390px){
    .instaSharePopup .profileQuizTitle span.bgColor {
        width: 87.5%;
    }
    .instaSharePopup .profileQuizTitle h2.profileHead {
        padding-top: 5px;
    }
    .instaSharePopup .profileData {
        width: 88%;
        gap: 0;
        padding-left: 2px;
        padding-right: 2px;
    }
    .instaSharePopup span.numProfile {
        font-size: 17px !important;
    }
    .instaSharePopup span.textProfile {
        font-size: 8px!important;
    }
}
@media (max-width: 360px){
    .instaSharePopup .profileQuizTitle {
        width: 76.5%;
    }
    .instaSharePopup span.textProfile {
        font-size: 7px!important;
    }
    .instaSharePopup span.numProfile {
        font-size: 14px !important;
    }
    .instaSharePopup .profileData {
        width: 87.5%;
    }
}
@media (max-width: 340px){
    .instaSharePopup .profileQuizTitle {
        width: 75.5%;
    }
    .instaSharePopup .profileData {
        width: 87%;
    }
}
@media (max-width: 320px){
    .instaSharePopup .profileQuizTitle {
        width: 74.5%;
    }
    .instaSharePopup .profileData {
        width: 87%;
    }
    .instaSharePopup span.numProfile {
        font-size: 13px !important;
    }
} */



.resultScoreWidth .res_Para h2 {
    font-family: TungstenBold;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.resultScoreWidth .res_Para {
    padding-right: 0;
}

.left_resultInner {
    max-width: 700px;
    margin: 0 auto;
}

.Score.resultScoreWidth .res_ActBtns {
    margin-top: 60px;
}

.canvas2.cardCanvas {
    padding: 0;
}

.sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
    padding: 30px;
    font-size: 50px;
}

.sharePopup.mobileRev.padLess01 .leftInfo .sharePara {
    margin-bottom: 45px;
}

.sharePopup.mobileRev.padLess01 .shareButtons {
    gap: 55px;
}

.instaPopupSh .instaHeading ul li {
    text-transform: unset;
    font-size: 24px;
}

.instaPopupSh .textContent .tagline p,
.instaPopupSh .copyBtn {
    font-size: 20px;
    font-family: "CairoRegular";
}

.instaPopupSh .copyBtn span {
    line-height: 1.5;
    display: block;
    color: #101924;
    font-weight: normal;
    font-family: "CairoRegular";
}

.copyBtn {
    cursor: pointer;
}

.modalWrapCustom section.modal-main:after,
.modalWrapCustom section.modal-main:before {
    z-index: -1;
}

@media (min-width: 1920px) {

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 26px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 80px;
    }

    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 32px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 32px !important;
    }
}

@media (max-width: 1919px) {

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 22px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 70px;
    }

    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 22px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 22px !important;
    }

    .Score.resultScoreWidth {
        padding: 100px 0;
    }
}

@media (max-width: 1680px) {
    .left_resultInner {
        max-width: 85%;
        margin: 0;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 26px;
        font-size: 35px;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons {
        gap: 40px;
    }

    .instaPopupSh .textContent .tagline p,
    .instaPopupSh .copyBtn {
        font-size: 18px;
    }

    .instaPopupSh .instaHeading ul li {
        font-size: 21px;
    }
}

@media (max-width: 1536px) {

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 18px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 60px;
    }

    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 18px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 18px !important;
    }

    .instaPopupSh .instaHeading ul li {
        font-size: 19px;
    }
}

@media (max-width: 1440px) {
    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 20px;
        font-size: 25px;
    }

    .sharePopup.mobileRev.padLess01 .leftInfo .sharePara {
        margin-bottom: 25px;
    }

    .instaPopupSh .textContent .tagline p,
    .instaPopupSh .copyBtn,
    .instaPopupSh .instaHeading ul li {
        font-size: 16px;
    }
}

@media (max-width: 1366px) {

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 16px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 40px;
    }

    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 16px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 16px !important;
    }

    .Score.resultScoreWidth .res_ActBtns {
        margin-top: 30px;
    }

    .left_resultInner {
        max-width: 450px;
        margin: 0 auto 0 7%;
    }
}

@media (max-width: 1200px) {
    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 16px;
        font-size: 25px;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons {
        gap: 30px;
    }
}

@media (max-width: 1024px) {

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 30px;
    }

    .left_resultInner {
        max-width: 100%;
        margin: 0 auto;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 10px;
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .instaPopupSh .instaHeading {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .res_ActBtns .btn_Parent {
        width: 100%;
    }

    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 30px;
        padding: 6px 10px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 30px;
        padding: 6px 10px;
    }

    .resultScoreWidth .mb_profile {
        display: block;
        margin: 50px auto 60px;
        max-width: 90%;
    }

    .Score.resultScoreWidth .right_result {
        display: none;
    }

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 30px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 50px;
        text-align: center;
    }

    .Score.resultScoreWidth .left_result {
        padding: 0px 0px;
    }

    .sharePopup.padLess01 .leftInfo .heading h2 {
        margin-bottom: 15px;
    }

    .padLess01 .leftInfo {
        margin-top: 35px;
    }

    .sharePopup.padLess01 [dir="ltr"].leftInfo .heading h2 {
        text-align: left;
    }

    .sharePopup.padLess01 [dir="rtl"].leftInfo .heading h2 {
        text-align: right;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 20px;
        font-size: 26px;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons {
        gap: 45px;
    }

    .instaPopupSh .textContent .tagline p,
    .instaPopupSh .copyBtn,
    .instaPopupSh .instaHeading ul li {
        font-size: 14px;
    }

    .InstaContent.instaShareCol {
        margin-top: 0;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 22px !important;
        padding: 2px 10px;
    }

    .instaPopupSh [dir="ltr"].instaHeading h2,
    .instaPopupSh [dir="ltr"].instaHeading.mbinstaHeading h2 {
        text-align: left;
    }

    .instaPopupSh [dir="rtl"].instaHeading h2,
    .instaPopupSh [dir="rtl"].instaHeading.mbinstaHeading h2 {
        text-align: right;
    }

    .instaPopupSh .instaHeading p {
        justify-content: flex-start;
        width: 100%;
    }

    .instaPopupSh .instaHeading p ul {
        padding-left: 15px;
    }

    .modalWrapCustom section.modal-main {
        margin: 50px 0;
        /* overflow-y: scroll; */
        top: 0;
        transform: translate(-50%, 0);
    }

    .padLess01 .leftInfo {
        margin-top: 20px;
    }

    .instaPopupSh .copyBtn {
        padding-left: 25%;
        padding-right: 25%;
    }

    .modalWrapCustom .modal.display-block {
        overflow-y: scroll;
        padding: 40px 0;
    }
}

@media (max-width: 768px) {
    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 22px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 22px !important;
    }

    .resultScoreWidth .res_Para p,
    .sharePopup.padLess01 .leftInfo p {
        font-size: 14px;
    }

    .resultScoreWidth .res_Para h2,
    .sharePopup.padLess01 .leftInfo .heading h2,
    .instaPopupSh .instaHeading h2 {
        font-size: 30px;
    }

    .Score.resultScoreWidth {
        padding: 80px 0;
    }
}

@media (max-width: 540px) {
    .instaPopupSh .copyBtn {
        padding-left: 0%;
        padding-right: 0%;
    }

    .instaPopupSh .copyBtn span {
        width: 170px !important;
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .resultScoreWidth .res_ActBtns .btn_Parent button {
        font-size: 18px;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons {
        gap: 30px;
    }

    .sharePopup.mobileRev.padLess01 .shareButtons .btn_Parent button.btnContent {
        padding: 12px;
        font-size: 16px;
    }

    .instaPopupSh .textContent .tagline p,
    .instaPopupSh .copyBtn,
    .instaPopupSh .instaHeading ul li {
        font-size: 12px;
    }

    .instaPopupSh .btn_Parent button {
        font-size: 18px !important;
    }
}

@media (max-width: 414px) {

    .instaPopupSh .textContent .tagline p,
    .instaPopupSh .copyBtn,
    .instaPopupSh .instaHeading ul li {
        font-size: 8px;
    }
}

/* 04.03.2023 Sh*/
.canvas2.buttonPopupCanvas {
    width: 100%;
    margin: 0 auto;
}

.Score.resultScoreWidth.resultContainer {
    padding: 90px 0;
}

.Score.resultContainer .left_result,
.Score.resultContainer .right_result {
    flex: unset;
}

.resultScoreWidth.resultContainer {
    max-width: 1500px;
    justify-content: space-between;
}

.Score.resultContainer .left_result {
    width: 52%;
    max-width: 690px;
}

.Score.resultContainer .right_result {
    width: 48%;
    max-width: 630px;
}

@media (max-width: 1680px) {
    .resultScoreWidth.resultContainer {
        max-width: 1290px;
    }

    .Score.resultContainer .right_result {
        max-width: 545px;
    }

    .Score.resultContainer .left_result {
        max-width: 590px;
    }

    .Score.resultContainer .left_resultInner {
        max-width: 100%;
        margin: 0;
    }
}

@media (max-width: 1550px) {
    .Score.resultContainer .right_result {
        max-width: 511px;
    }

    .Score.resultContainer .left_result {
        max-width: 560px;
    }

    .resultScoreWidth.resultContainer {
        max-width: 1180px;
    }
}

@media (max-width: 1440px) {
    .Score.resultContainer .right_result {
        max-width: 478px;
    }

    .resultScoreWidth.resultContainer {
        max-width: 1110px;
    }

    .Score.resultContainer .left_result {
        max-width: 470px;
    }
}

@media (max-width: 1440px) {
    .Score.resultContainer .right_result {
        max-width: 485px;
    }

    .Score.resultContainer .left_result {
        max-width: 570px;
    }
}

@media (max-width: 1366px) {
    .resultScoreWidth.resultContainer {
        max-width: 1050px;
    }

    .Score.resultContainer .left_result {
        max-width: 430px;
    }

    .Score.resultContainer .right_result {
        max-width: 450px;
    }

    .Score.resultContainer .left_resultInner {
        margin: 0;
    }
}

@media (max-width: 1280px) {
    .Score.resultContainer .right_result {
        max-width: 400px;
    }

    .Score.resultContainer .left_result {
        max-width: 440px;
    }

    .resultScoreWidth.resultContainer {
        max-width: 990px;
    }
}

@media (max-width: 1160px) {
    .resultScoreWidth.resultContainer {
        max-width: 900px;
    }
}

@media (max-width: 1024px) {
    .resultScoreWidth.resultContainer {
        max-width: 90%;
    }
}

@media (max-width: 911px) {
    .Score.resultContainer .right_result {
        max-width: 320px;
    }
}

@media (max-width: 768px) {
    .Score.resultContainer .left_result {
        max-width: 100%;
        width: 100%;
    }

    .Score.resultContainer .mb_profile {
        margin: 30px auto 45px;
        max-width: 80%;
    }

    .Score.resultContainer .res_Para {
        margin-bottom: 0;
        padding-right: 0;
    }
}

/* Shamrin Css End*/
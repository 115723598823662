.HomeMain {
    display: flex;
    min-height: calc(100vh - 50px);
    align-items: stretch;
    border-radius: 10px;
}


.HomeMain .leftHome,
.HomeMain .rightHome {
    flex: 1 1 0px;
}

.rightHome .CtaButtons .QuizBtn,
.rightHome .CtaButtons .resultBtn {
    width: 75%;
}

.rightHome .CtaButtons .QuizBtn button,
.nextButton button {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: auto;
    padding: 12px 10px;
    box-shadow: 2px 1px 40px -11px #474747ad;
    cursor: pointer;
    width: 100%;
    border: 1px solid transparent;
    color: var(--text-white);
}

.rightHome .CtaButtons .resultBtn button:hover {
    background-color: transparent;
    border: 1px solid;
    color: var(--white-color);
}

.CtaButtons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
}

.HomeHeading h2 {
    font-size: 35px;
    color: var(--text-white);
    margin-bottom: 20px;
    text-transform: uppercase;
}

.HomeHeading {
    margin-bottom: 60px;
}

.rightHome .CtaButtons button.disable {
    border: 1px solid #999999;
    opacity: 0.5;
    box-shadow: none;
    cursor: auto;
    background-color: #cccccc;
    color: #666666;
}

.rightHome .CtaButtons button.disable:hover {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.HomeHeading p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 28px;
    color: var(--text-white);
}

.leftHome {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightHome {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
}

.custom-shape-divider-bottom-1679474005 {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1679474005 svg {
    position: relative;
    display: block;
    width: 100%;
    height: 70px;
}

.custom-shape-divider-bottom-1679474005 .shape-fill {
    fill: #7C71FF;
}

.rightHomeMain {
    margin: 10px 40px;
    padding: 10% 0px;
}

.HomeMain .leftHome {
    min-height: 200px;
}

.leftHome img {
    width: 100%;
    height: inherit;
}

@media (min-width:320px) {
    .leftHome img {
        width: 100%;
    }

    .custom-shape-divider-bottom-1679474005 {
        display: initial;
    }

    .HomeMain {
        flex-direction: column;
        padding: 100px 0px;
    }

    .leftHome {
        padding: 10px 0px 0px 0px;
    }

    .rightHomeMain {
        margin: 10px 40px 30px 40px;
    }

    .HomeMain .leftHome img.homeIll {
        width: 130px;
    }

    .leftHome a:first-child {
        display: none;
    }

    .leftHome a:last-child {
        display: initial;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
    .leftHome img {
        width: 100%;
    }

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    .leftHome img {
        width: 100%;
    }

    .custom-shape-divider-bottom-1679474005 {
        display: none;
    }

    .HomeMain {
        flex-direction: row;
        padding: 0px 50px;

    }

    .HomeHeading {
        text-align: start;
    }

    .rightHome {
        align-items: normal;
    }

    .leftHome {
        padding: 10px 0px 0px 0px;
    }

    .rightHomeMain {
        margin: 10px 50px;
    }

    .HomeMain .leftHome img.homeIll {
        width: 200px;
    }

    .leftHome a:first-child {
        display: initial;
    }

    .leftHome a:last-child {
        display: none;
    }

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    .leftHome img {
        width: 100%;
    }

    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    .leftHome img {
        width: 60%;
    }

    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    .leftHome img {
        width: 60%;
    }

    /* hi-res laptops and desktops */
}



/* Shamrin starts here */
[dir='ltr'] .HomeHeading h2,
[dir='ltr'].rightHome .CtaButtons .QuizBtn button,
[dir='ltr'] .nextButton button {
    font-family: TungstenBold;
}

[dir='rtl'] .HomeHeading h2,
[dir='rtl'].rightHome .CtaButtons .QuizBtn button,
[dir='ltr'] .nextButton button {
    font-family: 'GEBold';
}

.homeMainSh .HomeHeading p {
    text-transform: unset;
}

.mainDiv.ltr .ft_menuLink .ft_links li a,
[dir='ltr'] .HomeHeading p {
    font-family: DINRegular;
}

.mainDiv.rtl .ft_menuLink .ft_links li a,
[dir='rtl'] .HomeHeading p {
    font-family: 'CairoRegular';
}


.homeMainSh .HomeHeading h2 {
    font-size: 55px;
}

.homeMainSh .HomeHeading p {
    font-size: 18px;
}

.HomeMain.homeMainSh .leftHome {
    padding-top: 0;
}

.homeMainSh .CtaButtons {
    align-items: center;
}

.homeMainSh .HomeHeading {
    padding-right: 8%;
}

.homeMainSh .rightHome .CtaButtons .QuizBtn button,
.homeMainSh .nextButton button {
    letter-spacing: 1px;
}

@media (min-width: 2560px) {
    .FooterInner {
        max-width: 30%;
    }
}

@media (min-width: 1920px) {
    .homeMainSh .HomeHeading h2 {
        font-size: 80px;
    }

    .homeMainSh .HomeHeading p {
        font-size: 26px;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 32px;
    }

    .homeMainSh .QuizBtn.btn_Parent.btn_red {
        margin-bottom: 6px;
    }
}

@media (max-width: 1919px) {
    .homeMainSh .HomeHeading p {
        font-size: 22px;
    }

    .homeMainSh .HomeHeading h2 {
        font-size: 70px;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 22px;
    }
}

@media (max-width: 1680px) {

    .rightHome .CtaButtons .QuizBtn,
    .rightHome .CtaButtons .resultBtn {
        width: 70%;
    }
}

@media (max-width: 1536px) {
    .homeMainSh .HomeHeading p {
        font-size: 18px;
    }

    .homeMainSh .HomeHeading h2 {
        font-size: 50px;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 18px;
    }
}

@media (max-width: 1366px) {
    .homeMainSh .HomeHeading p {
        font-size: 16px;
    }

    .homeMainSh .HomeHeading h2 {
        font-size: 40px;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 16px;
    }
}

@media (min-width: 1281px) {
    .homeMainSh .leftHome img {
        width: 75%;
    }

}

@media (max-width: 1280px) {
    .rightHomeMain {
        margin-right: 0px;
    }

    .homeMainSh .HomeHeading {
        margin-bottom: 30px;
    }

    .homeMainSh .rightHomeMain {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .homeMainSh .leftHome img {
        width: 75%;
    }
}

@media (max-width: 1024px) {
    .homeMainSh .leftHome img {
        width: 85%;
    }

    .homeMainSh .HomeHeading p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .homeMainSh .HomeHeading h2 {
        font-size: 30px;
    }
}

@media (max-width: 991px) {
    .homeMainSh .leftHome img {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .homeMainSh .leftHome img {
        width: 100%;
    }

    .homeMainSh .HomeHeading {
        padding-right: 0;
    }

    .HomeMain.homeMainSh {
        padding: 0px 2%;
    }

    nav .navInner {
        padding: 25px 2%;
    }

    .homeMainSh .rightHomeMain {
        margin-left: 20px;
    }
}

@media (max-width: 640px) {

    .rightHome .CtaButtons .QuizBtn,
    .rightHome .CtaButtons .resultBtn {
        width: 98%;
    }

    .homeMainSh .rightHomeMain {
        margin: 0;
        padding: 0 25px;
    }

    .homeMainSh .rightHome {
        padding-top: 40px;
    }

    .HomeMain.homeMainSh {
        padding: 80px 0 60px;
    }

    nav .navInner {
        padding: 20px 20px;
        align-items: center;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 22px;
    }

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        padding: 6px 10px;
    }
}

@media (max-width: 480px) {

    .homeMainSh .rightHome .CtaButtons .QuizBtn button,
    .homeMainSh .nextButton button {
        font-size: 18px;
    }
}

/* Shamrin ends here */
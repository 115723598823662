.Prompt p {
    white-space: pre-wrap;
    font-weight: 400;
    color: var(--text-white);
    padding: 0px 20px 20px 20px;
}

.Prompt {
    padding-top: 20px;
    margin: auto;
    border: 1px solid var(--text-white);
    font-family: arial;
    position: relative;
    margin-bottom: 40px;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 0px;
    bottom: unset;
}

.colon_dots span {
    width: 8px;
    height: 10px;
    display: block;
    background-color: var(--text-white);
}

/* [dir='ltr'] .colon_dots span{
    margin-right: 6px;
}
[dir='rtl'] .colon_dots span{
    margin-left: 6px;
} */
.colon_dots {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.prompt_Parent {
    position: relative;
}



/* Shamrin Css Start */

.Prompt.promptSh p {
    text-transform:unset;
    font-weight: normal !important;
    line-height: 1.5;
    font-size: 32px;
    padding: 20px 40px 10px 40px;
}

.Prompt.promptSh {
    padding-bottom: 30px;
}

@media (max-width: 1919px) {
    .Prompt.promptSh p {
        font-size: 22px;
    }
}

@media (max-width: 1536px) {
    .Prompt.promptSh p {
        font-size: 18px;
        padding: 10px 30px 10px 30px;
    }

    .Prompt.promptSh {
        padding-bottom: 15px;
    }
}

@media (max-width: 1366px) {
    .Prompt.promptSh p {
        padding: 5px 30px 10px 30px;
        font-size: 16px;
    }

    .Prompt.promptSh {
        padding-bottom: 10px;
    }

}

@media (max-width: 640px) {
    .Prompt.promptSh {
        width: 90%;
    }
}

/* Shamrin Css Ends */
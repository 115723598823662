.modal.display-block {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.modalOverlay {
  background-color: #030406a8;
}

section.modal-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  padding: 9px;
  text-align: center;
  box-sizing: border-box;
  z-index: 9999;
}

.instaHeading p {
  display: flex;
  width: 95%;
  justify-content: center;
}

section.modal-main:after {
  content: '';
  display: block;
  width: 100%;
  bottom: 0px;
  right: 0px;
  height: 40%;
  border: 1px solid var(--text-white);
  position: absolute;
  border-top: none;
}

section.modal-main:before {
  top: 0px;
  content: '';
  display: block;
  width: 100%;
  right: 0px;
  height: 36%;
  border: 1px solid var(--text-white);
  position: absolute;
  border-bottom: none;
}

.modalInnerDiv button.ModalClosebtn {
  position: absolute;
  top: -23px;
  right: -18px;
  background: none;
  border: none;
}

.modalInnerDiv button img {
  width: 15px;
}

.modalInnerDiv p {
  font-weight: 100;
  line-height: 1.5;
  margin-top: 0;
  color: #002241;
}

.modal.display-none section.modal-main {
  display: none;
}

@media (max-width: 640px) {
  section.modal-main {
    max-width: 80%;
  }
}
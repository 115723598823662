footer {
    width: 100%;
}
footer {
    background-color: var(--secondary-color);
    height: 50px;
}
.FooterInner {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: auto;
    width: 50%;
    justify-content: space-between;
}

.ft_brand {
    display: flex;
    justify-content: flex-start;
    gap: 22px;
    align-items: center;
}

/* .ft_volorant img, .ft_riot img {
    max-width: 100%;
} */
.ft_volorant img {
    width: 30px;
}

.ft_riot img {
    width: 60px;
}
.ft_menuLink .ft_links, .ft_socialLinks .ft_social {
    display: flex;
    list-style: none;
    padding: 0px;
}

.ft_menuLink .ft_links {
    gap: 37px;
}

.ft_socialLinks .ft_social {
    gap: 8px;
}

.ft_socialLinks .ft_social li a {
    color: var(--primary-color);
}
.ft_menuLink .ft_links li a {
    font-size: 10px;
    white-space: nowrap;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-white);
    text-decoration: none;
}
@media (min-width:320px)  { 
    /* .FooterInner,.ft_menuLink .ft_links{
        flex-direction: column;
        align-items: center;
    }
    .FooterInner>div {
        margin-bottom: 10px;
    } */

    footer{
        padding: 10px 0px;
        display:none;
    }
    /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { 
    .FooterInner,.ft_menuLink .ft_links{
        flex-direction: row;
    }
    .FooterInner>div {
        margin-bottom: 0px;
    }
    footer{
        padding: 0px;
        display:flex;
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }


/* Shamrin Css start */
footer {
    height: 70px;
}
.ft_riot img {
    width: 95px;
}
.ft_volorant img {
    width: 49px;
}
.FooterInner {
    max-width: 700px;
}
ul.ft_social li a svg {
    font-size: 20px;
}

.ft_menuLink .ft_links li a {
    font-size: 16px;
    font-weight: normal;
}

.ft_socialLinks .ft_social {
    gap: 12px;
}
.FooterInner {
    max-width: 750px;
}
@media (max-width: 1920px){
    .ft_menuLink .ft_links {
        gap: 28px;
    }

}
@media (max-width: 1919px){
    .FooterInner {
        max-width: 800px;
    }
    .ft_socialLinks .ft_social {
        gap: 12px;
    }
    ul.ft_social li a svg {
        font-size: 20px;
    }
}
@media (max-width: 1600px){
    footer {
        height: 50px;
    }
    .ft_riot img {
        width: 60px;
    }
    .ft_volorant img {
        width: 30px;
    }
    .FooterInner {
        max-width: 550px;
    }
    ul.ft_social li a svg {
        font-size: 14px;
    }
    
    .ft_menuLink .ft_links li a {
        font-size: 13px;
    }
}
@media (max-width: 1200px){
    .ft_menuLink .ft_links li a {
        font-size: 11px;
    }
}
@media (max-width: 1080px){
    .ft_menuLink .ft_links {
        gap: 16px;
    }
}
@media (max-width: 991px){
    .FooterInner {
        width: 100%;
    }
}
/* Shamrin Css end */
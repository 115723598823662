.mainDiv {
    min-height: 100vh;
    background-image:url('./assets/Images/BG.png');
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}
.Switch {
    display: flex;
    justify-content: flex-end;
}

.Switch select#SwitchLang {
    color: var(--text-white);
    padding: 0px 5px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: 900;
}


/* Shamrin Css starts here*/

.overlayLine{
    background-image:url('./assets/Images/white-lines.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
@media (max-width:1024px)  {
    .FooterInner {
        width: 60%;
    }
}
@media (max-width:814px)  {
    .FooterInner {
        width: 90%;
    }
}
@media (max-width:768px)  {
    .mainDiv {
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
    } 
}
/* Shamrin Css End Here*/

@media (min-width:320px)  {
   
    /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }